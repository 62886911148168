import { SET_HISTORY_LOAD, SET_DAY, SET_DAYS_VISITED, SET_SHARE_LOAD, SET_POPUP_STATUS, SET_POSTCARD_STATUS, SET_PLAYLIST_STATUS, SET_PROMOCODE_STATUS, SET_POPUP_POSTCARD_STATUS, SET_POPUP_GIFT_STATUS, SET_FRAME_STATUS } from './constants';

export function hystoryLoad(status) {
  return {
    type: SET_HISTORY_LOAD,
    payload: status,
  };
}

export function setDay(day) {
  return {
    type: SET_DAY,
    payload: day,
  };
}

export function setDaysVisited(days) {
  return {
    type: SET_DAYS_VISITED,
    payload: days,
  };
}

export function shareLoad(status) {
  return {
    type: SET_SHARE_LOAD,
    payload: status,
  };
}

export function popupStatus(status) {
  return {
    type: SET_POPUP_STATUS,
    payload: status,
  };
}

export function postcardStatus(status) {
  return {
    type: SET_POSTCARD_STATUS,
    payload: status,
  };
}

export function playlistStatus(status) {
  return {
    type: SET_PLAYLIST_STATUS,
    payload: status,
  };
}

export function promocodeStatus(status) {
  return {
    type: SET_PROMOCODE_STATUS,
    payload: status,
  };
}

export function popupPostcardStatus(status) {
  return {
    type: SET_POPUP_POSTCARD_STATUS,
    payload: status,
  };
}

export function popupGiftStatus(status) {
  return {
    type: SET_POPUP_GIFT_STATUS,
    payload: status,
  };
}

export function frameStatus(status) {
  return {
    type: SET_FRAME_STATUS,
    payload: status,
  };
}