import React, { useState, useEffect } from 'react';
import PageHead from '../PageHead';
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';

import audio from '../../audio/cat.mp3';


const Gift45 = ({index, goHome, goBot, token, mobile, gifts, userId, scope}) => {

  const [play, setPlay] = useState(false);
  useEffect(() => {
    const sound = document.getElementById('sound');
    if(play){
      sound.play();
    }
    else{
      sound.pause();
    }
  }, [play]);

  const playClick = () => setPlay(!play);

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
  
            {!mobile ? (
              <>
                <div className="gift-title">Урчание для&nbsp;расслабления</div>
                Время расслабиться и&nbsp;получить удовольствие.<br />
                Слушайте мурчание котика и&nbsp;пусть в&nbsp;новом году всё&nbsp;будет замурчательно.
              </>
            ) : (
              <>
                <div className="gift-title">Урчание для&nbsp;расслабления</div>
                <p>Время расслабиться и&nbsp;получить удовольствие.</p>
                Слушайте мурчание котика и&nbsp;пусть в&nbsp;новом году всё&nbsp;будет замурчательно.

                <div className="audio-box">
                  <audio src={audio} id="sound" loop />
                  <div className={play ? 'audio-box__play active' : 'audio-box__play'} onClick={playClick} />
                  Для лучшего воспроизведения включите наушники
                </div>
              </>
            )}
          </div>

          {!mobile && (
            <div className="gift__dsc">
              <div className="audio-box">
                <audio src={audio} id="sound" loop />
                <div className={play ? 'audio-box__play active' : 'audio-box__play'} onClick={playClick} />
                Для лучшего воспроизведения<br />
                включите наушники
              </div>
            </div>
          )}
  
        </div>
      </div>
  
      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift45);
