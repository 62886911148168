/*//test
export const APP_ID = 7210361//7218104; //id приложения
export const DOMAIN = 'https://whiskas.multicontent.ru'; //домен апи
export const GROUP_ID = 189168814; //id сообщества
*/



//test
export const APP_ID = 7233306//7218104; //id приложения
export const DOMAIN = 'https://whiskas.multicontent.ru'; //домен апи
export const GROUP_ID = 189168814; //id сообщества


/*
//prod
export const APP_ID = 7210361//id приложения
export const DOMAIN = '' //домен апи
export const GROUP_ID = 134319933; //id сообщества
*/
