import React, { useEffect } from 'react';
import PageHead from '../PageHead';
import ShareButton from '../ShareButton';
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';
import ButtonToBottom from '../../components/ButtonToBottom';

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

import img1 from '../../img/gifts/tu-du-1.jpg';
import img2 from '../../img/gifts/tu-du-2.jpg';
import img3 from '../../img/gifts/tu-du-3.jpg';
import img4 from '../../img/gifts/tu-du-4.jpg';
import img5 from '../../img/gifts/tu-du-5.jpg';

const button = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="orange" d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="#FFB30F"/>
    <path className="black" d="M28.9717 39.2444C28.9626 39.2533 28.9535 39.2621 28.9447 39.2716L26.1687 42.0658V36.4518C26.1687 35.8432 25.6784 35.3499 25.0738 35.3499C24.469 35.3499 23.9787 35.8432 23.9787 36.4518V42.0658L21.2027 39.2716C20.7826 38.8336 20.0892 38.8215 19.6542 39.2444C19.2193 39.6671 19.2072 40.3646 19.6272 40.8023C19.6363 40.8117 19.6451 40.8206 19.6542 40.8295L24.2984 45.5038C24.3999 45.6056 24.5201 45.6865 24.6523 45.7419C24.9217 45.8566 25.2259 45.8566 25.4955 45.7419C25.6278 45.6865 25.7479 45.6057 25.8491 45.5038L30.4933 40.8295C30.9283 40.4068 30.9403 39.7093 30.5203 39.2716C30.0999 38.8338 29.4067 38.8218 28.9717 39.2444Z" fill="black"/>
    <path className="black" d="M27.0147 19.1087H27.2785C27.8196 19.1087 28.2598 18.6657 28.2598 18.1213C28.2598 17.577 27.8197 17.134 27.2785 17.134H27.0147C26.4737 17.134 26.0336 17.577 26.0336 18.1213C26.0336 18.6657 26.4737 19.1087 27.0147 19.1087Z" fill="black"/>
    <path className="black" d="M22.9128 19.1087H23.1767C23.7177 19.1087 24.1579 18.6657 24.1579 18.1213C24.1579 17.577 23.7177 17.134 23.1767 17.134H22.9128C22.3718 17.134 21.9316 17.577 21.9316 18.1213C21.9316 18.6657 22.3718 19.1087 22.9128 19.1087Z" fill="black"/>
    <path className="black" d="M23.1432 21.0693C23.1432 21.854 23.7409 22.5008 24.5022 22.5729V23.7777C24.5022 24.1076 24.768 24.375 25.0957 24.375C25.4237 24.375 25.6894 24.1076 25.6894 23.7777V22.5729C26.4506 22.5005 27.0483 21.8537 27.0483 21.0691C27.0483 20.2364 26.375 19.5588 25.5475 19.5588H24.6442C23.8165 19.5588 23.1433 20.2365 23.1432 21.0693ZM24.6442 20.7536H25.5475C25.7175 20.7536 25.8611 20.8982 25.8611 21.0694C25.8611 21.2404 25.7175 21.3849 25.5475 21.3849H25.0959H24.6443C24.4742 21.3849 24.3306 21.2404 24.3306 21.0693C24.3305 20.8981 24.4741 20.7535 24.6442 20.7536Z" fill="black"/>
    <path className="black" d="M36.2114 7.79614H13.9801C13.4391 7.79614 12.9989 8.23907 12.9989 8.78345V31.154C12.9989 31.6984 13.4391 32.1413 13.9801 32.1413H36.2114C36.7524 32.1413 37.1926 31.6984 37.1926 31.154V8.78345C37.1926 8.23907 36.7524 7.79614 36.2114 7.79614ZM18.384 19.4151L18.3842 19.4131C18.7986 17.0877 20.8029 15.401 23.1536 15.401H27.038C29.3877 15.401 31.3917 17.0868 31.807 19.4112L31.8076 19.4165C31.8558 19.8076 31.8835 20.1211 31.8835 20.2766C31.8835 22.9651 29.7098 25.1524 27.038 25.1524H23.1536C20.4818 25.1524 18.3082 22.9651 18.3082 20.2766C18.3081 20.121 18.3365 19.799 18.384 19.4151ZM30.799 14.569L32.2046 14.0665L32.0785 15.6766C31.699 15.2558 31.269 14.8835 30.799 14.569ZM19.3925 14.5689C18.9225 14.8835 18.4925 15.2558 18.1129 15.6767L17.9868 14.0665L19.3925 14.5689ZM23.1535 27.1269H27.0379C29.0059 27.1269 30.7817 28.3535 31.5227 30.1667H18.6686C19.4098 28.3535 21.1856 27.1269 23.1535 27.1269ZM30.5521 26.142C32.5742 24.9102 33.8457 22.6821 33.8457 20.2766C33.8457 19.9301 33.8196 19.5818 33.7681 19.2405L34.2797 12.7041C34.3058 12.3694 34.1634 12.0476 33.8986 11.8432C33.7282 11.7117 33.5161 11.6394 33.3015 11.6394C33.1893 11.6394 33.0788 11.6586 32.9732 11.6963L27.9585 13.4886C27.6545 13.4471 27.3453 13.4261 27.038 13.4261H23.1536C22.8463 13.4261 22.5372 13.4471 22.2332 13.4886L17.2187 11.6964C17.1129 11.6585 17.0024 11.6393 16.8901 11.6393C16.6753 11.6393 16.4633 11.7117 16.2934 11.8429C16.0283 12.0471 15.8857 12.369 15.9118 12.704L16.4234 19.241C16.3718 19.5819 16.3457 19.9301 16.3457 20.2766C16.3457 22.6821 17.6173 24.9102 19.6395 26.142C19.319 26.3373 19.0146 26.5591 18.7294 26.8054C17.7078 27.6879 16.9583 28.8723 16.5965 30.1667H14.9614V9.77076H35.2303V30.1667H33.595C33.2333 28.872 32.4837 27.6877 31.4622 26.8054C31.1769 26.5591 30.8725 26.3373 30.5521 26.142Z" fill="black"/>
  </svg>
)

const Gift3 = ({index, goHome, goBot, token, mobile, gifts, userId, scope}) => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
  
            {mobile ? (
              <>
                <div className="gift-title">Проведите день как&nbsp;котик</div>
                5 пунктов, которые помогут вам быть на&nbsp;одной волне с&nbsp;любимцем
                {scope != '' && <ButtonToBottom />}
              </>
            ) : (
              <div className="gift__head-with-buttons">
                <div>
                  <div className="gift-title">Проведите день как&nbsp;котик</div>
                  5 пунктов, которые помогут вам быть на&nbsp;одной волне с&nbsp;любимцем
                </div>
                <div>
                  <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                </div>
              </div>
            )}
          </div>
          {mobile ? (
            <>
              <div className="gift__outer-title">1. Проснитесь раньше кота и&nbsp;начните будить его громким мяуканьем</div>
              <div className="gift__dsc">
                <img src={img1} className="gift__img" />
              </div>
              <div className="gift__outer-title">2. Требуйте немедленного завтрака. Понюхайте его. Не&nbsp;ешьте</div>
              <div className="gift__dsc">
                <img src={img2} className="gift__img" />
              </div>
              <div className="gift__outer-title">3. Проспите полдня</div>
              <div className="gift__dsc">
                <img src={img3} className="gift__img" />
              </div>
              <div className="gift__outer-title">4. Сделайте вид, что хотите, чтобы вас гладили. Убегайте от&nbsp;того, кто купился</div>
              <div className="gift__dsc">
                <img src={img4} className="gift__img" />
              </div>
              <div className="gift__outer-title">5. Не забудьте о&nbsp;ночной пробежке по&nbsp;квартире</div>
              <div className="gift__dsc">
                <img src={img5} className="gift__img" />
              </div>
              <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
            </>
          ) : (
            <>
              <div className="dgift3">
                <div>
                  <div className="gift__outer-title">1. Проснитесь раньше кота и&nbsp;начните будить его&nbsp;громким мяуканьем</div>
                  <div className="gift__dsc">
                    <img src={img1} className="gift__img" />
                  </div>
                </div>
                <div>
                  <div className="gift__outer-title">2. Требуйте немедленного завтрака. Понюхайте его. Не&nbsp;ешьте</div>
                  <div className="gift__dsc">
                    <img src={img2} className="gift__img" />
                  </div>
                </div>
              </div>
  
              <div className="dgift3">
                <div>
                  <div className="gift__outer-title">3. Проспите полдня<br />&nbsp;</div>
                  <div className="gift__dsc">
                    <img src={img3} className="gift__img" />
                  </div>
                </div>
                <div>
                  <div className="gift__outer-title">4. Сделайте вид, что&nbsp;хотите, чтобы вас гладили. Убегайте от&nbsp;того, кто&nbsp;купился</div>
                  <div className="gift__dsc">
                    <img src={img4} className="gift__img" />
                  </div>
                </div>
              </div>
  
              <div className="dgift3">
                <div>
                  <div className="gift__outer-title">5. Не&nbsp;забудьте о&nbsp;ночной пробежке по&nbsp;квартире</div>
                  <div className="gift__dsc">
                    <img src={img5} className="gift__img" />
                  </div>
                </div>
                <div />
              </div>
            </>
          )}
        </div>
      </div>
  
      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift3);
