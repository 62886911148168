import React, { useState, useEffect } from 'react';
import { connect as reduxConnect } from 'react-redux';
import View from '@vkontakte/vkui/dist/components/View/View';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import '@vkontakte/vkui/dist/vkui.css';
import Bot from './Bot';
import PageHead from './PageHead';
import { setDay, setDaysVisited, popupStatus, postcardStatus, playlistStatus, promocodeStatus, popupGiftStatus, frameStatus } from '../components/store/actions';
import { DOMAIN } from '../constants';
import axios from 'axios';
import Snackbar from '@vkontakte/vkui/dist/components/Snackbar/Snackbar';
import Rules from './Rules';
import RelesLnk from './RelesLnk';


//первая декада
import Gift1 from '../components/gifts/Gift1';
import Gift2 from '../components/gifts/Gift2';
import Gift3 from '../components/gifts/Gift3';
import Gift4 from '../components/gifts/Gift4';
import Gift5 from '../components/gifts/Gift5';
import Gift6 from '../components/gifts/Gift6';
import Gift7 from '../components/gifts/Gift7';
import Gift8 from '../components/gifts/Gift8';
import Gift10 from '../components/gifts/Gift10';
import Gift13 from '../components/gifts/Gift13';
//первая декада

/********/
import Gift9 from '../components/gifts/Gift9';
import Gift12 from '../components/gifts/Gift12';
import Gift14 from '../components/gifts/Gift14';
import Gift15 from '../components/gifts/Gift15';
import Gift16 from '../components/gifts/Gift16';
import Gift17 from '../components/gifts/Gift17';
/********/

//вторая декада
import Gift21 from '../components/gifts/Gift21';
import Gift22 from '../components/gifts/Gift22';
import Gift23 from '../components/gifts/Gift23';
import Gift11 from '../components/gifts/Gift11';
import Gift24 from '../components/gifts/Gift24';
import Gift25 from '../components/gifts/Gift25';
import Gift26 from '../components/gifts/Gift26';
import Gift37 from '../components/gifts/Gift37';
import Gift27 from '../components/gifts/Gift27';
//вторая декада


//третья декада
import Gift40 from '../components/gifts/Gift40';
import Gift41 from '../components/gifts/Gift41';
import Gift42 from '../components/gifts/Gift42';
import Gift43 from '../components/gifts/Gift43';
import Gift47 from '../components/gifts/Gift47';
import Gift44 from '../components/gifts/Gift44';
import Gift45 from '../components/gifts/Gift45';
import Gift46 from '../components/gifts/Gift46';
import Gift48 from '../components/gifts/Gift48';
import Gift50 from '../components/gifts/Gift50';
import Gift51 from '../components/gifts/Gift51';
//третья декада

const Home = ({ fetchedUser, token, userData, userId, setDay, setDaysVisited, gifts, botStatus, popupStatus, scope, postcardStatus, postcard, playlistStatus, setUserData, promocodeStatus, popupGiftStatus, frameStatus, frame }) => {
  const [activePanel, setActivePanel] = useState('home');
  const [alert, setAlert] = useState(false);

  const mobile = window.innerWidth <= 700;

  useEffect(() => {
    const hash = window.location.hash;
    const hashId = parseInt(hash.substr(1, hash.length - 1));
    if(hashId){
      for(let i = 0; i < userData.gifts.length; i++){
        if(userData.gifts[i].id == hashId){
          openGift(userData.gifts[i].id, userData.gifts[i].date);
          break;
        }
      }
    }
  },[]);

  const goHome = () => {
    setActivePanel('home');
  }

  const goBot = () => {
    setActivePanel('bot');
  }

  const getDecade = () => {
    const date = new Date();
    const today = date.getDate();
    if(today >= 1 && today <= 10){
      return 1;
    }
    if(today > 10 && today <= 21){
      return 2;
    }
    return 3;
  }

  const openGift = (id, date) => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramsObj = {
      vk_access_token_settings: searchParams.get('vk_access_token_settings'),
      vk_app_id: searchParams.get('vk_app_id'),
      vk_are_notifications_enabled: searchParams.get('vk_are_notifications_enabled'),
      vk_is_app_user: searchParams.get('vk_is_app_user'),
      vk_is_favorite: searchParams.get('vk_is_favorite'),
      vk_language: searchParams.get('vk_language'),
      vk_platform: searchParams.get('vk_platform'),
      vk_ref: searchParams.get('vk_ref'),
      vk_user_id: searchParams.get('vk_user_id'),
      sign: searchParams.get('sign'),
    }

    axios.get(`${DOMAIN}/api/set.php`, {
      params: {
        day: date,
        ...paramsObj
      }
    })
    .then(function ({data}) {
      if(data.visited){
        const array = gifts.days;
        array.push(date);
        setDaysVisited(array);
        setActivePanel(`gift${id}`);
        setDay(date);

        if(id == '37' || id == '47'){
          axios.get(`${DOMAIN}/api/get.php`, {
            params: {
              uniq: 0,
              ...paramsObj
            }
          })
          .then(function (response) {
            response.data.gifts.forEach(gift => {
              if(gift.id == '37'){
                postcardStatus({
                  status: gift.status,
                  img: gift.postcard
                });
              }

              if(gift.id == '47'){
                frameStatus({
                  status: gift.status,
                  img: gift.postcard
                });
              }
            });
          })
          .catch(function (error) {
            console.log(error);
          });
        }

        if(id == '51'){
          promocodeStatus(data.promocode);
        }

      }
      else{
        popupGiftStatus(true);
      }
    })
    .catch(function (error) {
      console.log(error);
    }); 
  }

  const createButtons = () => {
    const today = new Date().getDate();

    const array = [];
    for(let i = 1; i <= 31; i++){
      let gift = null;

      userData.gifts.forEach(e => {
        if(e.date == i){
          gift = e;
        }
      });

      if(gift){
        array.push(
          <div
            key={`item${i}`}
            data-visited={gift.visited || gifts.days.includes(gift.date)}
            className={gift.date == today ? 'button today' : 'button'}
            data-id={gift.id}
            data-day={i}
            onClick={() => openGift(gift.id, gift.date)}
          >
            <i>{i}</i>
            <div>{i}{gift.date == today && <small>сегодня</small>}</div>

            { ( gift.id == '37' && gift.status ) && (
              <>
                {gift.status == '1' && <span className="button__status" data-id="ok" />}
                { (gift.status == '2' && ( postcard.data && postcard.data.status == '2') ) && <span className="button__status" data-id="fail" />}
              </>
            )}

            { ( gift.id == '47' && gift.status ) && (
              <>
                {gift.status == '1' && <span className="button__status" data-id="ok" />}
                { (gift.status == '2' && ( frame.data && frame.data.status == '2') ) && <span className="button__status" data-id="fail" />}
              </>
            )}

          </div>
        );

        if(gift.id == '37' && gift.status){
          if(!postcard.data){
            postcardStatus({
              status: gift.status,
              img: gift.postcard
            });
          }
        }

        if(gift.id == '47' && gift.status){
          if(!frame.data){
            frameStatus({
              status: gift.status,
              img: gift.postcard
            });
          }
        }

        if(gift.id == '27'){
          playlistStatus(gift.playlist);
        }
      }
      else{
        array.push(
          <div
            data-day={i}
            key={`item${i}`}
            className="button disable"
          >
            <div>{i}</div>
          </div>
        );
      }
    }
    return array;
  }

  if(mobile){
    return(
      <div>
        <View activePanel={activePanel}>
          <Panel id="home">
            <div className="wrap">
              <PageHead goHome={goHome} goBot={goBot}>
                <div className="home__text">
                В эту замурчательную пору WHISKAS® приготовил для вас подарки! Следите за календарем и получайте разнообразные призы каждый день!
                </div>
              </PageHead>
              <div className="buttons">
                {createButtons()}
                <RelesLnk />
              </div>
            </div>
          </Panel>
          <Panel id="gift1"><Gift1 goHome={goHome} goBot={goBot} index={1} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift2"><Gift2 goHome={goHome} goBot={goBot} index={2} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift3"><Gift3 goHome={goHome} goBot={goBot} index={3} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift4"><Gift4 goHome={goHome} goBot={goBot} index={4} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift5"><Gift5 goHome={goHome} goBot={goBot} index={5} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift6"><Gift6 goHome={goHome} goBot={goBot} index={6} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift7"><Gift7 goHome={goHome} goBot={goBot} index={7} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift8"><Gift8 goHome={goHome} goBot={goBot} index={8} token={token} decade={getDecade()} mobile={mobile} fetchedUser={fetchedUser} botStatus={botStatus} userId={userId} scope={scope} /></Panel>
          <Panel id="gift10"><Gift10 goHome={goHome} goBot={goBot} index={10} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift13"><Gift13 goHome={goHome} goBot={goBot} index={13} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift14"><Gift14 goHome={goHome} goBot={goBot} index={14} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift15"><Gift15 goHome={goHome} goBot={goBot} index={15} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift16"><Gift16 goHome={goHome} goBot={goBot} index={16} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift17"><Gift17 goHome={goHome} goBot={goBot} index={17} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          

          <Panel id="gift21"><Gift21 goHome={goHome} goBot={goBot} index={21} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift22"><Gift22 goHome={goHome} goBot={goBot} index={22} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift23"><Gift23 goHome={goHome} goBot={goBot} index={23} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift11"><Gift11 goHome={goHome} goBot={goBot} index={11} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift24"><Gift24 goHome={goHome} goBot={goBot} index={24} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift12"><Gift12 goHome={goHome} goBot={goBot} index={12} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift25"><Gift25 goHome={goHome} goBot={goBot} index={25} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift9"><Gift9 goHome={goHome} goBot={goBot} index={9} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift26"><Gift26 goHome={goHome} goBot={goBot} index={26} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift37"><Gift37 goHome={goHome} goBot={goBot} index={37} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} botStatus={botStatus} /></Panel>
          <Panel id="gift27"><Gift27 goHome={goHome} goBot={goBot} index={27} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} botStatus={botStatus} /></Panel>

          <Panel id="gift40"><Gift40 goHome={goHome} goBot={goBot} index={40} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift41"><Gift41 goHome={goHome} goBot={goBot} index={41} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift42"><Gift42 goHome={goHome} goBot={goBot} index={42} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift43"><Gift43 goHome={goHome} goBot={goBot} index={43} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift47"><Gift47 goHome={goHome} goBot={goBot} index={47} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} botStatus={botStatus} /></Panel>
          <Panel id="gift44"><Gift44 goHome={goHome} goBot={goBot} index={44} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift45"><Gift45 goHome={goHome} goBot={goBot} index={45} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift46"><Gift46 goHome={goHome} goBot={goBot} index={46} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift48"><Gift48 goHome={goHome} goBot={goBot} index={48} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift50"><Gift50 goHome={goHome} goBot={goBot} index={50} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>
          <Panel id="gift51"><Gift51 goHome={goHome} goBot={goBot} index={51} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} /></Panel>

          <Panel id="bot">
            <div className="wrap">
              <PageHead goHome={goHome} goBack={goHome} goBot={goBot} />
              <div className="page page_gift">
                <Bot userId={userId} botStatus={botStatus} mobile={mobile} />
              </div>
            </div>
          </Panel>
        </View>

        <Rules mobile={mobile} />
      </div>
    )
  }
  else{
    return(
      <div className="wrap_desctope">
        {activePanel === 'home' && (
          <div id="home">
            <div className="wrap wrap_desctope">
              <PageHead goHome={goHome} goBot={goBot} fetchedUser={fetchedUser}>
                <div className="home__text">
                  В эту замурчательную пору WHISKAS® приготовил для вас подарки! Следите за календарем и получайте разнообразные призы каждый день!
                </div>
              </PageHead>
              <div className="buttons">
                {createButtons()}
              </div>
            </div>
          </div>
        )}
        {activePanel == 'gift1' && <Gift1 goHome={goHome} goBot={goBot} index={1} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift2' && <Gift2 goHome={goHome} goBot={goBot} index={2} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift3' && <Gift3 goHome={goHome} goBot={goBot} index={3} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift4' && <Gift4 goHome={goHome} goBot={goBot} index={4} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift5' && <Gift5 goHome={goHome} goBot={goBot} index={5} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift6' && <Gift6 goHome={goHome} goBot={goBot} index={6} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift7' && <Gift7 goHome={goHome} goBot={goBot} index={7} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift8' && <Gift8 goHome={goHome} goBot={goBot} index={8} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} fetchedUser={fetchedUser} botStatus={botStatus} />}
        {activePanel == 'gift10' && <Gift10 goHome={goHome} goBot={goBot} index={10} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift13' && <Gift13 goHome={goHome} goBot={goBot} index={13} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        
        {activePanel == 'gift14' && <Gift14 goHome={goHome} goBot={goBot} index={14} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift15' && <Gift15 goHome={goHome} goBot={goBot} index={15} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift16' && <Gift16 goHome={goHome} goBot={goBot} index={16} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift17' && <Gift17 goHome={goHome} goBot={goBot} index={17} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}

        {activePanel == 'gift21' && <Gift21 goHome={goHome} goBot={goBot} index={21} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift22' && <Gift22 goHome={goHome} goBot={goBot} index={22} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift23' && <Gift23 goHome={goHome} goBot={goBot} index={23} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift11' && <Gift11 goHome={goHome} goBot={goBot} index={11} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift24' && <Gift24 goHome={goHome} goBot={goBot} index={24} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift12' && <Gift12 goHome={goHome} goBot={goBot} index={12} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift25' && <Gift25 goHome={goHome} goBot={goBot} index={25} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift9' && <Gift9 goHome={goHome} goBot={goBot} index={9} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift26' && <Gift26 goHome={goHome} goBot={goBot} index={26} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift37' && <Gift37 goHome={goHome} goBot={goBot} index={37} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} botStatus={botStatus} />}
        {activePanel == 'gift27' && <Gift27 goHome={goHome} goBot={goBot} index={27} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} botStatus={botStatus} />}


        {activePanel == 'gift40' && <Gift40 goHome={goHome} goBot={goBot} index={40} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift41' && <Gift41 goHome={goHome} goBot={goBot} index={41} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift42' && <Gift42 goHome={goHome} goBot={goBot} index={42} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift43' && <Gift43 goHome={goHome} goBot={goBot} index={43} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift47' && <Gift47 goHome={goHome} goBot={goBot} index={47} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} botStatus={botStatus} />}
        {activePanel == 'gift44' && <Gift44 goHome={goHome} goBot={goBot} index={44} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift45' && <Gift45 goHome={goHome} goBot={goBot} index={45} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift46' && <Gift46 goHome={goHome} goBot={goBot} index={46} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift48' && <Gift48 goHome={goHome} goBot={goBot} index={48} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift50' && <Gift50 goHome={goHome} goBot={goBot} index={50} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}
        {activePanel == 'gift51' && <Gift51 goHome={goHome} goBot={goBot} index={51} token={token} decade={getDecade()} mobile={mobile} userId={userId} scope={scope} />}

        {activePanel == 'bot' && (
          <div className="wrap">
            <PageHead goHome={goHome} goBack={goHome} goBot={goBot} />
            <div className="page page_gift">
              <Bot userId={userId} botStatus={botStatus} mobile={mobile} />
            </div>
          </div>
        )}
        

        {alert && (
          <Snackbar onClose={() => setAlert(false)}>
            Просмотр этого подарка доступен только на мобильном устройстве
          </Snackbar>
        )}

        <Rules mobile={mobile} />
        <div className="rules-lnk" onClick={() => popupStatus(true)}>Правила акции</div>
      </div>
    )
  }
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
    postcard: state.postcard,
    frame: state.frame
  }),
  { setDay, setDaysVisited, popupStatus, postcardStatus, playlistStatus, promocodeStatus, popupGiftStatus, frameStatus }
)(Home);
