import { SET_PROMOCODE_STATUS } from './constants';

const initialState = {
  status: null
};

export default function promocode(state = initialState, action) {
  if(action.type === SET_PROMOCODE_STATUS){
    return {
      ...state,
      status: action.payload,
    };
  }
	return state;
}