import React, { useState, useEffect } from 'react';
import PageHead from '../PageHead';
import ShareButton from '../ShareButton';
import { connect as reduxConnect } from 'react-redux';

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

import img1 from '../../img/gifts/g16/ico1.svg';
import img2 from '../../img/gifts/g16/ico2.svg';

import RelesLnk from '../../components/RelesLnk';

const Gift16 = ({index, goHome, goBot, token, mobile, gifts, userId, scope})  => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  const [step, setStep] = useState(1);
  const [value, setValue] = useState({
    'a': 0,
    'b': 0,
    'c': 0
  });
  const max = 4;
  const rand = Math.round(Math.random() * 2);

  const result = () => {
    const array = [
      'Ваш новый год точно будет замечательным! Но вот&nbsp;замурчательным… не&nbsp;уверены. Кажется, вас не&nbsp;очень привлекают котики. Может быть, вы просто еще не&nbsp;нашли того самого, который украдет ваше сердечко?',
      'Ваш новый год будет теплым, мягким и&nbsp;пушистым. Прямо, как ваши кошачьи друзья! Не&nbsp;забудьте подарить им подарок в&nbsp;этот праздник. Как насчет новогоднего WHISKAS® "Праздничный микс"? 😉',
      'Больше Мяу, мяу, мяу! У&nbsp;вас будет замурчательным не&nbsp;только Новый Год, но&nbsp;и&nbsp;вообще каждый день, ведь вы настоящий котофанат! Желаем вам и&nbsp;вашем пушистикам отлично провести праздничные дни.'
    ]

    return array[getIndex()];
  }

  const getIndex = () => {
    if(value['a'] >= 2){
      return 0;
    }
    if(value['b'] >= 2){
      return 1;
    }
    if(value['c'] >= 2){
      return 2;
    }

    return rand;
  }

  const next = v => {
    if(v){
      const obj = {...value};
      obj[v] = obj[v] + 1
      setValue(obj);
    };
    setStep(step + 1);

    if(step === 3){
      window.scrollTo(0, 0);
    }
  }

  const back = () => {
    setStep(1);
    setValue({
      'a': 0,
      'b': 0,
      'c': 0
    });
    window.scrollTo(0, 0);
  }

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
        <div className="page page_gift">
          <div className={`gift gift${index}`}>

          <div className="gift-f">
            <div>
              <div className="gift__head">
                <div className="gift__head-num">{gifts.day}</div>


                {mobile ? (
                  <>
                    <div className="gift-title">Насколько замурчательным будет ваш Новый&nbsp;год?</div>
                    {step != max ? (
                      <>
                        Пройдите праздничный тест и&nbsp;узнайте, каким будет ваш Новый год
                      </>
                    ) : (
                      <>
                        <p dangerouslySetInnerHTML={{ __html: result()}} />
                      </>
                    )}
                  </>
                ) : (
                  <div className="gift__head-with-buttons">
                    <div>
                      <div className="gift-title">Насколько замурчательным будет ваш Новый&nbsp;год? </div>
                      {step != max && (
                        <>
                          Пройдите праздничный тест и&nbsp;узнайте, каким будет ваш Новый год
                        </>
                      )}
                    </div>
                    <div>
                      {step === max && <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} gift16Index={getIndex()} />}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              {step === 1 && (
                <div className="gift__dsc gift__dsc_test">
                  {!mobile && <img src={img1} />}
                  <div className="gift__test-title">{step}/3</div>
                  <div className="gift__testquestion">Какой подарок вы бы выбрали на Новый&nbsp;Год?</div>
                  <div className="gift__test-buttons">
                    <div className="gift__test-button" onClick={() => next('a')}>Смартфон</div>
                    <div className="gift__test-button" onClick={() => next('b')}>Свитер с котятами</div>
                    <div className="gift__test-button" onClick={() => next('c')}>Свитер? Пфф… Давайте просто котят</div>
                  </div>
                </div>
              )}

              {step === 2 && (
                <div className="gift__dsc gift__dsc_test">
                  {!mobile && <img src={img1} />}
                  <div className="gift__test-title">{step}/3</div>
                  <div className="gift__testquestion">А как будете праздновать?</div>
                  <div className="gift__test-buttons">
                    <div className="gift__test-button" onClick={() => next('a')}>Пойду тусоваться с друзьями</div>
                    <div className="gift__test-button" onClick={() => next('b')}>В кругу семьи и любимых котиков</div>
                    <div className="gift__test-button" onClick={() => next('c')}>Послушаю куранты, загадаю желание, возьму корм и пойду раздавать его бездомным пушистикам</div>
                  </div>
                </div>
              )}

              {step === 3 && (
                <div className="gift__dsc gift__dsc_test">
                  {!mobile && <img src={img1} />}
                  <div className="gift__test-title">{step}/3</div>
                  <div className="gift__testquestion">Какое у вас пожелание на следующий год?</div>
                  <div className="gift__test-buttons">
                    <div className="gift__test-button" onClick={() => next('a')}>Чтобы было много денег, любви и счастья!</div>
                    <div className="gift__test-button" onClick={() => next('b')}>Чтобы год был теплый, как кошачий животик!</div>
                    <div className="gift__test-button" onClick={() => next('c')}>Чтобы мои кошечки<br/>(да и вообще все кошечки)<br/>были счастливы!</div>
                  </div>
                </div>
              )}

              { (!mobile && step === max) && (
                <div className="gift__dsc gift__dsc_test gift__dsc_test-result">
                  {!mobile && <img src={img2} />}
                  <div className="gift__testquestion">
                    <p dangerouslySetInnerHTML={{ __html: result()}} />
                  </div>

                  <div className="share-button" onClick={() => back()}>Пройти тест заново</div>
                </div>
              )}

              { (mobile && step === max) && (
                <>
                  <div className="share-button" onClick={() => back()}>Пройти тест заново</div>
                  <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} gift16Index={getIndex()} />
                </>
              )}
            </div>
          </div>


        </div>
      </div>

      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift16);
