import { SET_POPUP_POSTCARD_STATUS } from './constants';

const initialState = {
  status: false
};

export default function popuppostcard(state = initialState, action) {
  if(action.type === SET_POPUP_POSTCARD_STATUS){
    return {
      ...state,
      status: action.payload,
    };
  }
	return state;
}