import React, { useState, useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import PageHead from '../PageHead';
import ShareButton from '../ShareButton';
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';

import Myaskin_582х638 from '../../img/gifts/cats/1_Myaskin_582х638.jpg';
import Myaskin_650x300 from '../../img/gifts/cats/1_Myaskin_650x300.jpg';
import Dymok_582х638 from '../../img/gifts/cats/2_Dymok_582х638.jpg';
import Dymok_650х300 from '../../img/gifts/cats/2_Dymok_650х300.jpg';
import Dart_582х638 from '../../img/gifts/cats/3_Dart_582х638.jpg';
import Dart_650х300 from '../../img/gifts/cats/3_Dart_650х300.jpg';
import Klyaksa_582х638 from '../../img/gifts/cats/4_Klyaksa_582х638.jpg';
import Klyaksa_650х300 from '../../img/gifts/cats/4_Klyaksa_650х300.jpg';
import Muskus_582х638 from '../../img/gifts/cats/5_Muskus_582х638.jpg';
import Muskus_650х300 from '../../img/gifts/cats/5_Muskus_650х300.jpg';
import Hrom_582х638 from '../../img/gifts/cats/6_Hrom_582х638.jpg';
import Hrom_650х300 from '../../img/gifts/cats/6_Hrom_650х300.jpg';
import Richard_582х638 from '../../img/gifts/cats/7_Richard_582х638.jpg';
import Richard_650х300 from '../../img/gifts/cats/7_Richard_650х300.jpg';
import Pandora_582х638 from '../../img/gifts/cats/8_Pandora_582х638.jpg';
import Pandora_650х300 from '../../img/gifts/cats/8_Pandora_650х300.jpg';
import Inki_582х638 from '../../img/gifts/cats/9_Inki_582х638.jpg';
import Inki_650х300 from '../../img/gifts/cats/9_Inki_650х300.jpg';
import Yupiter_582х638 from '../../img/gifts/cats/10_Yupiter-582х638.jpg';
import Yupiter_650х300 from '../../img/gifts/cats/10_Yupiter_650х300.jpg';
import Angara_582х638 from '../../img/gifts/cats/11_Angara_582х638.jpg';
import Angara_650х300 from '../../img/gifts/cats/11_Angara_650х300.jpg';
import Carap_582х638 from '../../img/gifts/cats/12_Carap_582х638.jpg';
import Carap_650х300 from '../../img/gifts/cats/12_Carap_650х300.jpg';
import Darling_582х638 from '../../img/gifts/cats/13_Darling_582х638.jpg';
import Darling_650х300 from '../../img/gifts/cats/13_Darling_650х300.jpg';
import Biskvit_582х638 from '../../img/gifts/cats/14_Biskvit_582х638.jpg';
import Biskvit_650х300 from '../../img/gifts/cats/14_Biskvit_650х300.jpg';
import Villi_582х638 from '../../img/gifts/cats/15_Villi_582х638.jpg';
import Villi_650х300 from '../../img/gifts/cats/15_Villi_650х300.jpg';

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

const Gift6 = ({index, goHome, goBot, token, mobile, gifts, userId, scope}) => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  const [result, setResult] = useState(false);

  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState(0);

  const handleChange1 = (event, newValue) => setValue1(newValue);
  const handleChange2 = (event, newValue) => setValue2(newValue);
  const handleChange3 = (event, newValue) => setValue3(newValue);

  const rand = Math.round(Math.random() * 2);

  const calcResult = () => {
    const result = value1 + value2 + value3;

    let name = '';
    let imgs = [];

    if( value1 == 2 || value2 == 2 || value3 == 2 ){
      name = ['Юпитер', 'Ангара' ,'Царап'][rand];
      imgs = [
        [Yupiter_582х638, Yupiter_650х300],
        [Angara_582х638, Angara_650х300],
        [Carap_582х638, Carap_650х300]
      ][rand]
    }

    if( value1 == 1 || value2 == 1 || value3 == 1 ){
      name = ['Бисквит', 'Дарлинг', 'Вилли'][rand];
      imgs = [
        [Biskvit_582х638, Biskvit_650х300],
        [Darling_582х638, Darling_650х300],
        [Villi_582х638, Villi_650х300]
      ][rand]
    }

    if( (value1 == 1 && value2 == 1) || (value1 == 1 && value3 == 1) || (value2 == 1 && value3 == 1) ){
      name = ['Клякса', 'Мускус', 'Хром'][rand];
      imgs = [
        [Klyaksa_582х638, Klyaksa_650х300],
        [Muskus_582х638, Muskus_650х300],
        [Hrom_582х638, Hrom_650х300]
      ][rand]
    }

    if( (value1 == 2 && value2 == 2) || (value1 == 2 && value3 == 2) || (value2 == 2 && value3 == 2) ){
      name = ['Ричард', 'Пандора', 'Инки'][rand];

      imgs = [
        [Richard_582х638, Richard_650х300],
        [Pandora_582х638, Pandora_650х300],
        [Inki_582х638, Inki_650х300]
      ][rand]
    }

    if(!result){
      name = 'Мяскин';
      imgs = [Myaskin_582х638, Myaskin_650x300];
    }

    if(result == 6){
      name = 'Дарт';
      imgs = [Dart_582х638, Dart_650х300];
    }

    if(value1 == 1 && value2 == 1 && value3 == 1){
      name = 'Дымок';
      imgs = [Dymok_582х638, Dymok_650х300];
    }

    return [name, imgs];
  }

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome} />
      <div className="page page_gift">
      <div className={`gift gift${index}`}>
        {!result ? (
          <>
            <div className="gift__head">
              <div className="gift__head-num">{gifts.day}</div>
              <div className="gift-title">Узнайте свое кошачье имя!</div>
              Ответьте на&nbsp;3&nbsp;вопроса, чтобы узнать свое идеальное кошачье имя.
            </div>

            <div className="gift__dsc gift__dsc_center">

              <div className={!mobile ? 'gift-sliders' : null}>
                <div>
                  <div className="slider-title">Какой у вас характер?</div>

                  <div className="slider">
                    <Slider
                      defaultValue={0}
                      step={1}
                      min={0}
                      max={2}
                      value={value1}
                      onChange={handleChange1}
                    />
                  </div>
                  <div className="slider-label">
                    <div>Озорной</div>
                    <div>Угрюмый</div>
                  </div>
                </div>
                <div>
                  <div className="slider-title">Как часто вы общаетесь с&nbsp;друзьями?</div>

                  <div className="slider">
                    <Slider
                      defaultValue={0}
                      step={1}
                      min={0}
                      max={2}
                      value={value2}
                      onChange={handleChange2}
                    />
                  </div>
                  <div className="slider-label">
                    <div>Постоянно</div>
                    <div>Редко</div>
                  </div>
                </div>
                <div className="last">
                  <div className="slider-title">Насколько пушистым будет ваш хвост?</div>

                  <div className="slider">
                    <Slider
                      defaultValue={0}
                      step={1}
                      min={0}
                      max={2}
                      value={value3}
                      onChange={handleChange3}
                    />
                  </div>
                  <div className="slider-label">
                    <div>Как у белки</div>
                    <div>Как у сфинкса</div>
                  </div>
                </div>
              </div>
              {!mobile && <div className="share-button" onClick={() => setResult(true)}>Узнать результат</div>}
            </div>

            {mobile && <div className="share-button" onClick={() => setResult(true)}>Узнать результат</div>}
          </>
        ) : (
          <>
          {mobile ? (
            <>
              <div className="gift-f">
                <div>
                  <div className="gift__head gift6-result">
                    <div className="gift6-result__in">
                      <div className="gift__head-num">{gifts.day}</div>
                      <div className="gift-title">Узнайте свое кошачье имя!</div>
                      <p>Твое кошачье имя</p>
                      <div className="gift-title">{calcResult()[0]}</div>
                    </div>
                    <img src={calcResult()[1][1]} />
                  </div>
                </div>
                <div>
                  <ShareButton index={index} token={token} g6Name={calcResult()[0]} g6Img={calcResult()[1][1]} day={gifts.day} userId={userId} scope={scope} />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="gift-f">
                <div>
                  <div className="gift__head">
                    <div className="gift__head-num">{gifts.day}</div>
                    <div className="g6-dsc">Твое кошачье имя</div>
                    <div className="gift-title">{calcResult()[0]}</div>
                  </div>
                </div>
              </div>
              <div className="gift__dsc">
                <div className="dgift__dsc dgift__dsc-center">
                  <div>
                    <img src={calcResult()[1][0]} width="291" height="319" />
                  </div>
                  <div>
                    <ShareButton index={index} token={token} g6Name={calcResult()[0]} g6Img={calcResult()[1][1]} day={gifts.day} userId={userId} scope={scope}  />
                  </div>
                </div>
              </div>
            </>
          )}
          </>
        )}
        </div>
      </div>

      {mobile && <RelesLnk />}
    </div>
  );
  
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift6);
