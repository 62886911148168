import React, { useEffect } from 'react';
import PageHead from '../PageHead';
import ShareButton from '../ShareButton';
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';
import ButtonToBottom from '../../components/ButtonToBottom';

import download from '../../img/download/checklist2.jpg';

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

const Gift22 = ({index, goHome, goBot, token, mobile, gifts, userId, scope}) => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  return (
    <div className={mobile ? 'wrap' : 'wrap desctope'}>
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
            {mobile ? (
              <>
                <div className="gift-title">Чек-лист</div>
                Начните 2020 с&nbsp;хороших привычек и&nbsp;приятных подарочков для вашего котика, чтобы он&nbsp;оставался счастливым весь год!
                {scope != '' && <ButtonToBottom />}
              </>
            ) : (
              <>
                <div className="gift__head-with-buttons">
                  <div>
                  <div className="gift-title">Чек-лист</div>
                  Начните 2020 с&nbsp;хороших привычек и&nbsp;приятных подарочков для вашего котика, чтобы он&nbsp;оставался счастливым весь год!
                  </div>
                  <div>
                    <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="gift__dsc">
            <ul className="list">
              <li>Купите или сделайте котейке новую игрушку</li>
              <li>Обновите когтеточку и&nbsp;мисочки</li>
              <li>Оборудуйте котодомик с&nbsp;полочками и&nbsp;норками, если его еще нет</li>
              <li>Начните купать кота не&nbsp;реже раза в&nbsp;месяц, как бы он не&nbsp;сопротивлялся</li>
              <li>Расчесывайте кота. Гладкая шерстка — лучшее украшение</li>
              <li>Стригите коготочки по&nbsp;необходимости. Почувствуйте себя мастером мяуникюра</li>
              <li>Чистите ушки специальным раствором хотя бы раз в&nbsp;неделю</li>
              <li>Водите котика к&nbsp;ветеринару на&nbsp;прививки и&nbsp;профилактические осмотры</li>
              <li>Гладьте любимца почаще и&nbsp;покупайте ему вкусняшки</li>
              <li>Сводите пушистика к&nbsp;специальному парикмахеру&nbsp;—&nbsp;грумеру</li>
            </ul>
  
            <a href={download} target="_blank" className="download-lnk">
              <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="orange" fillRule="evenodd" clipRule="evenodd" d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="#FFB30F"/>
                <path className="black" fillRule="evenodd" clipRule="evenodd" d="M23.8268 10.2132L23.7835 21.8994L22.4371 20.521C21.9808 20.0538 21.2183 20.0482 20.7479 20.5227C20.2742 21.0005 20.2712 21.7657 20.7337 22.2393L24.1306 25.7171C24.3575 25.9494 24.6601 26.0676 24.9648 26.0694L24.974 26.0731C25.2776 26.0739 25.5803 25.9571 25.812 25.7234L29.2346 22.2708C29.6943 21.807 29.7 21.0321 29.2331 20.5541C28.763 20.0728 28.01 20.0698 27.5439 20.5399L26.1835 21.9123L26.2268 10.2221C26.2293 9.55017 25.6941 9.00247 25.0313 9.00001C24.364 8.99753 23.8293 9.54073 23.8268 10.2132ZM34.6 31.561H15.4V23.0189C15.4 22.3526 14.8627 21.805 14.2 21.805C13.5326 21.805 13 22.3484 13 23.0189V32.7862C13 33.119 13.1341 33.4223 13.3528 33.6465C13.5695 33.8655 13.8684 34.0001 14.1984 34.0001H35.8016C36.1324 34.0001 36.4313 33.8643 36.6508 33.644C36.8671 33.4244 37 33.1211 37 32.7862V23.0189C37 22.3526 36.4627 21.805 35.8 21.805C35.1326 21.805 34.6 22.3484 34.6 23.0189V31.561Z" fill="black"/>
              </svg>
            </a>
          </div>
  
          {mobile && <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />}
        </div>
      </div>
  
      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift22);
