import React, { useEffect } from 'react';
import PageHead from '../PageHead';
import ShareButton from '../ShareButton';
import gift from './../../img/gifts/comics.jpg';
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

const Gift10 = ({index, goHome, goBot, token, mobile, gifts, userId, scope})  => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
  
            {mobile ? (
              <>
                <div className="gift-title">Котокомикс</div>
                Этот милейший комикс точно заставит вас улыбнуться в&nbsp;новом году!
              </>
            ) : (
              <>
                <div className="gift__head-with-buttons">
                  <div>
                    <div className="gift-title">Котокомикс</div>
                    Этот милейший комикс точно заставит вас улыбнуться в&nbsp;новом году!
                  </div>
                  <div>
                    <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="gift__dsc gift__dsc_img">
            <img src={gift} />
          </div>
          {mobile && <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />}
        </div>
      </div>
      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift10);
