import React, { useEffect } from 'react';
import PageHead from '../PageHead';
import { connect as reduxConnect } from 'react-redux';
import ShareButton from '../ShareButton';
import RelesLnk from '../../components/RelesLnk';
import ButtonToBottom from '../../components/ButtonToBottom';

import img from '../../img/gifts/g42.jpg'

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

const gift5Text = () => (
  <>
    <p>Возможно, ваш кот мурлыкает по&nbsp;одной из&nbsp;этих причин:</p>
    <ul className="mlist">
      <li>Любовь. <span>Если мурлыкая питомец лижет вам руки, можете не&nbsp;сомневаться в&nbsp;его привязанности.</span></li>
      <li>Настроение. <span>Если глаза кота полуприкрыты, а&nbsp;хвост неподвижен,&nbsp;— он мурлыкает от&nbsp;удовольствия.</span></li>
      <li>Голод. <span>Если питомец голоден и&nbsp;требует еды, к&nbsp;мурлыканью добавится мяуканье.</span></li>
      <li>Утешение. <span>Коты могут мурлыкать, чтобы успокоить себя, когда у&nbsp;них что-то&nbsp;болит.</span></li>
      <li>Стресс. <span>Мурлыканье провоцируют незнакомые места, запахи или люди.</span></li>
    </ul>
  </>
)

const Gift42 = ({index, goHome, goBot, token, mobile, gifts, userId, scope}) => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
  
            {mobile ? (
              <>
                <div className="gift-title">Полезные советы</div>
                Почему котик мяукает?
                {scope != '' && <ButtonToBottom />}
              </>
            ) : (
              <>
                <div className="gift__head-with-buttons">
                  <div>
                    <div className="gift-title">Полезные советы</div>
                    Почему котик мяукает?
                  </div>
                  <div>
                    <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                  </div>
                </div>
              </>
            )}
  
  
  
          </div>
          {mobile ? (
            <>
              <div className="gift42-box"></div>
              <div className="gift__dsc gift__dsc_center gift__dsc_norad gift__dsc_gift5">
              {gift5Text()}
              </div>
              <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
            </>
          ) : (
            <>
              <div className="gift__dsc">
                <div className="dgift__dsc">
                  <div>
                    <img src={img} width="291" height="319" />
                  </div>
                  <div>
                  {gift5Text()}
                  </div>
                </div>
              </div>
            </>
          )}
  
        </div>
      </div>
  
      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift42);
