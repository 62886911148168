import { SET_DAY, SET_DAYS_VISITED } from './constants';

const initialState = {
  day: false,
  days: [],
};

export default function gifts(state = initialState, action) {
  if(action.type === SET_DAY){
    return {
      ...state,
      day: action.payload,
    };
  }
  if(action.type === SET_DAYS_VISITED){
    return {
      ...state,
      days: action.payload,
    };
  }
	return state;
}