import React, { useEffect } from 'react';
import PageHead from '../PageHead';
import { connect as reduxConnect } from 'react-redux';
import ShareButton from '../ShareButton';
import RelesLnk from '../../components/RelesLnk';
import ButtonToBottom from '../../components/ButtonToBottom';

import img from '../../img/gifts/g24.jpg'

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

const gift5Text = () => (
  <>
    <p>Какой котик не&nbsp;любит вкусняшек? Главное, чтобы они были для&nbsp;него полезны. Посмотрите список продуктов, которые точно не&nbsp;нужно давать любимцу.</p>
    <p>Котов не&nbsp;стоит кормить человеческой едой. Молоко и&nbsp;сыр, например, могут вызвать диарею и&nbsp;расстройство пищеварения. А&nbsp;некоторые домашние продукты вообще ни&nbsp;в&nbsp;коем случае не должны попадать в&nbsp;его рацион. Если кот съел что-то из&nbsp;этого&nbsp;—&nbsp;поспешите к&nbsp;ветеринару:</p>
    <ul className="mlist">
      <li>шоколад</li>
      <li>виноград</li>
      <li>изюм</li>
      <li>кофеин</li>
      <li>лук</li>
      <li>чеснок</li>
      <li>ксилитол (заменитель сахара)</li>
      <li>соль</li>
    </ul>
    <p>Не подвергайте котов риску&nbsp;—&nbsp;покупайте готовые корма для животных. У WHISKAS® вы найдете разнообразные вкусы и&nbsp;текстуры, созданные с&nbsp;учетом возраста и&nbsp;особенностей котика. Ему точно понравится 😋</p>
  </>
)

const Gift5 = ({index, goHome, goBot, token, mobile, gifts, userId, scope}) => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
  
            {mobile ? (
              <>
                <div className="gift-title">Полезные советы</div>
                Можно ли кошкам домашнюю еду?
                {scope != '' && <ButtonToBottom />}
              </>
            ) : (
              <>
                <div className="gift__head-with-buttons">
                  <div>
                    <div className="gift-title">Полезные советы</div>
                    Можно ли кошкам домашнюю еду?
                  </div>
                  <div>
                    <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                  </div>
                </div>
              </>
            )}
  
  
  
          </div>
          {mobile ? (
            <>
              <div className="gift24-box"></div>
              <div className="gift__dsc gift__dsc_center gift__dsc_norad gift__dsc_gift5">
              {gift5Text()}
              </div>
              <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
            </>
          ) : (
            <>
              <div className="gift__dsc">
                <div className="dgift__dsc">
                  <div>
                    <img src={img} width="291" height="319" />
                  </div>
                  <div>
                  {gift5Text()}
                  </div>
                </div>
              </div>
            </>
          )}
  
        </div>
      </div>
  
      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift5);
