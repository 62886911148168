import React, { useState } from 'react';
import connect from '@vkontakte/vk-connect';
import { connect as reduxConnect } from 'react-redux';
import Snackbar from '@vkontakte/vkui/dist/components/Snackbar/Snackbar';
import { hystoryLoad, shareLoad } from '../components/store/actions';
import axios from 'axios';
import { DOMAIN, APP_ID } from '../constants';

import bg from '../img/gen/bgn.jpg';
import g5 from '../img/gen/g5.jpg';
import g10 from '../img/gen/g10.jpg';
import marker from '../img/gen/marker.png';
import postcard1 from '../img/gen/postcard1.jpg';
import tudu1 from '../img/gen/tu-du-1.jpg';
import g4 from '../img/gen/g4.jpg';
import g11 from '../img/gifts/gift4_img3.svg';
import g12 from '../img/gifts/img3_2.jpg';

import g24 from '../img/gifts/g24m.jpg';
import g14 from '../img/gifts/ches.jpg';
import tudu23 from '../img/gen/tu-du-1_2.jpg';
import g26 from '../img/gifts/comics2.jpg';
import g43 from '../img/gifts/comics3.jpg';

import g42 from '../img/gifts/g42m.jpg';
import g15 from '../img/gifts/find.jpg';
import g44 from '../img/gifts/g44sh.jpg';
import g48 from '../img/gifts/g48m.jpg';

import g50 from '../img/gifts/comics4.jpg';
import g51 from '../img/gifts/promo2.jpg';

const ShareButton = ({index, token, shareText, stateHystory, hystoryLoad, shareLoad, stateShare, g6Name, g6Img,  day, userId, g7id, gift13Img, gift8Img, scope, gift37, gift16Index, gift47, g46Img, g46Name}) => {

  const loadImages = (sources, callback) => {
    var images = {};
    var loadedImages = 0;
    var numImages = 0;
    for(var src in sources) {
      numImages++;
    }
    for(var src in sources) {
      images[src] = new Image();
      //images[src].crossOrigin = 'anonymous';
      images[src].onload = function() {
        if(++loadedImages >= numImages) {
          callback(images);
        }
      };
      images[src].src = sources[src];
    }
  }

  const submitImage = (file, uid, day, vendor) => {
    if(vendor == 'photo'){
      shareLoad('load');
    }
    else{
      hystoryLoad('load');
    }

    const searchParams = new URLSearchParams(window.location.search);
    const paramsObj = {
      vk_access_token_settings: searchParams.get('vk_access_token_settings'),
      vk_app_id: searchParams.get('vk_app_id'),
      vk_are_notifications_enabled: searchParams.get('vk_are_notifications_enabled'),
      vk_is_app_user: searchParams.get('vk_is_app_user'),
      vk_is_favorite: searchParams.get('vk_is_favorite'),
      vk_language: searchParams.get('vk_language'),
      vk_platform: searchParams.get('vk_platform'),
      vk_ref: searchParams.get('vk_ref'),
      vk_user_id: searchParams.get('vk_user_id'),
      sign: searchParams.get('sign'),
    }

    axios({
      method: 'post',
      url: `${DOMAIN}/api/save_file.php`,
      data: {
        file,
        day,
        ...paramsObj
      }
    })
    .then((response) => {
      if(token){
        if(vendor == 'photo'){
          connect.send(
            "VKWebAppCallAPIMethod",
            {
              "method": "photos.getWallUploadServer",
              "request_id": `${response.data.file}|photo|${uid}|${token}`,
              "params": {
                "v":"5.103",
                "access_token":token
              }
            }
          );
        }
        else{
          connect.send(
            "VKWebAppCallAPIMethod",
            {
              "method": "stories.getPhotoUploadServer",
              "request_id": `${response.data.file}|story`,
              "params": {
                "add_to_news": "1",
                "link_text": "Whiskas",
                "link_url": `https://vk.com/app${APP_ID}#utm_source=sharing_post`,
                "v":"5.103",
                "access_token":token
              }
            }
          );
        }
      }
    })
    .catch((error) => {
      console.log(error)
    });
  }
  
  const shareImage = vendor => {
    const canvas = document.getElementById('sharing-generator');
    canvas.width = 720;
    if(vendor == 'photo'){
      canvas.height = 1015;
    }
    else{
      canvas.height = 1280;
    }

    const ctx = canvas.getContext('2d');

    if(index == 1){
      const sources = {
        image1: bg
      };

      loadImages(sources, images => {
        ctx.drawImage(images.image1, 0, 0);
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 520, 720, 440);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const titleData = [
          'Вот насколько',
          'замурчательным будет мой',
          'год!'
        ];

        let topTitle = 380;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        ctx.font = '40px rotondacregular';
        ctx.fillStyle = '#000000';

        const tmpData = [
          'Мое кошачье чутье говорит,',
          'что в этом году вам нужно',
          'проявлять чуткость к любимым,',
          'быть верными и бороться за счастье',
          'в мурличной жизни. Ну и почаще',
          'обнимайте своих котов, конечно ♥',
        ];
        let t = 624;

        tmpData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), t);
          t += 50
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 2){
      const sources = {
        image1: bg,
        image2: marker
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 500, 720, 490);

        ctx.drawImage(images.image2, 50, 550);
        ctx.drawImage(images.image2, 50, 690);
        ctx.drawImage(images.image2, 50, 830);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const titleData = [
          '10 любопытных задачек,',
          'которые нужно успеть',
          'выполнить перед Новым годом'
        ];

        let topTitle = 360;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        ctx.font = '36px rotondacregular';
        ctx.fillStyle = '#000000';

        const tmpData = [
          'Задобрите Новый Год хорошим',
          'поступком — покормите',
          'пушистых в приюте'
        ];
        let t = 580;

        tmpData.forEach(e => {
          ctx.fillText(e , 140, t);
          t += 36
        });

        const tmpData2 = [
          'Выкиньте ненужное, чтобы',
          'освободить место',
          'для нового'
        ];
        let t2 = 720;

        tmpData2.forEach(e => {
          ctx.fillText(e , 140, t2);
          t2 += 36
        });

        const tmpData3 = [
          'Свернитесь калачиком и',
          'подумайте, чего бы вам',
          'хотелось в 2020 году'
        ];
        let t3 = 860;

        tmpData3.forEach(e => {
          ctx.fillText(e , 140, t3);
          t3 += 36
        });
        

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 3){
      const sources = {
        image1: bg,
        image2: tudu1,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 110, 510, 500, 500);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';
        const titleData = [
          'Как провести свой день',
          'по-кошачьи'
        ];

        let topTitle = 360;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        ctx.font = '32px rotondacregular';
        ctx.fillStyle = '#ffffff';
        const tmpData = [
          '1. Проснитесь раньше кота и начните',
          'будить его громким мяуканьем.'
        ];
        let t = 460;

        tmpData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), t);
          t += 32
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 4){
      const sources = {
        image1: bg,
        image2: g4,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 70, 474);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';
        const titleData = [
          'Что подарить котику',
          'на Новый год?'
        ];

        let topTitle = 360;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 5){
      const sources = {
        image1: bg,
        image2: g5,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 35, 620);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const textString1 = 'Полезные советы';
        const textWidth1 = ctx.measureText(textString1).width;
        ctx.fillText(textString1 , (canvas.width/2) - (textWidth1 / 2), 420);

        ctx.font = '36px rotondacregular';
        ctx.fillStyle = '#ffffff';
        const textString2 = '5 пунктов, чтобы понять, как котик себя';
        const textWidth2 = ctx.measureText(textString2).width;
        ctx.fillText(textString2 , (canvas.width/2) - (textWidth2 / 2), 510);

        const textString3 = 'чувствует';
        const textWidth3 = ctx.measureText(textString3).width;
        ctx.fillText(textString3 , (canvas.width/2) - (textWidth3 / 2), 550);

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 6){
      if(g6Img){
        const sources = {
          image1: bg,
          image2: g6Img,
        };
  
        loadImages(sources, function(images) {
          ctx.drawImage(images.image1, 0, 0);
    
          if(g6Name){
            ctx.drawImage(images.image2, 35, 650);
    
            ctx.font = '48px rotondacbold';
            ctx.fillStyle = '#fbdc36';
            const textString1 = 'Мое кошачье имя';
            const textWidth1 = ctx.measureText(textString1).width;
            ctx.fillText(textString1 , (canvas.width/2) - (textWidth1 / 2), 420);
      
            ctx.font = '70px rotondacbold';
            ctx.fillStyle = '#ffffff';
            const textString2 = g6Name;
            const textWidth2 = ctx.measureText(textString2).width;
            ctx.fillText(textString2 , (canvas.width/2) - (textWidth2 / 2), 570);
    
            const dataURL = canvas.toDataURL('image/jpeg', 1.0);
            submitImage(dataURL, userId, day, vendor);
          }
        });
      }
    }

    if(index == 7){
      const sources = {
        image1: bg
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 520, 720, 440);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const textString1 = 'Предсказание для моего';
        const textWidth1 = ctx.measureText(textString1).width;
        ctx.fillText(textString1 , (canvas.width/2) - (textWidth1 / 2), 420);

        const textString2 = 'котика на 2020 год!';
        const textWidth2 = ctx.measureText(textString2).width;
        ctx.fillText(textString2 , (canvas.width/2) - (textWidth2 / 2), 470);

        if(g7id){
          ctx.font = '40px rotondacregular';
          ctx.fillStyle = '#000000';

          const tmpData = g7id.split('|');
          let t = 680;

          tmpData.forEach(e => {
            ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), t);
            t += 50
          });
        }

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 8){
      if(gift8Img){
        const sources = {
          image1: bg,
          image2: gift8Img,
        };
  
        loadImages(sources, function(images) {
          ctx.drawImage(images.image1, 0, 0);
          ctx.drawImage(images.image2, 0, 570, 720, 292);
  
          ctx.font = '48px rotondacbold';
          ctx.fillStyle = '#fbdc36';
          const titleData = [
            'С Новым 2020 годом, котики!'
          ];
  
          let topTitle = 400;
          titleData.forEach(e => {
            ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
            topTitle += 50
          });
  
          const dataURL = canvas.toDataURL('image/jpeg', 1.0);
          submitImage(dataURL, userId, day, vendor);
        });
      }
    }

    if(index == 10){
      const sources = {
        image1: bg,
        image2: g10,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 60, 590);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const textString1 = 'Мой котокомикс для';
        const textWidth1 = ctx.measureText(textString1).width;
        ctx.fillText(textString1 , (canvas.width/2) - (textWidth1 / 2), 420);

        const textString2 = 'замурчательного настроения';
        const textWidth2 = ctx.measureText(textString2).width;
        ctx.fillText(textString2 , (canvas.width/2) - (textWidth2 / 2), 470);

        const textString3 = 'в 2020 году';
        const textWidth3 = ctx.measureText(textString3).width;
        ctx.fillText(textString3 , (canvas.width/2) - (textWidth3 / 2), 520);

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 13){
      const sources = {
        image1: bg,
        image2: gift13Img,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 0, 570, 720, 292);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';
        const titleData = [
          'С Новым 2020 годом, котики!'
        ];

        let topTitle = 400;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }


    /* вторая декада */
    if(index == 21){
      const sources = {
        image1: bg
      };

      loadImages(sources, images => {
        ctx.drawImage(images.image1, 0, 0);
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 520, 720, 440);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const titleData = [
          'Вот насколько',
          'замурчательным будет мой',
          'год!'
        ];

        let topTitle = 380;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        ctx.font = '38px rotondacregular';
        ctx.fillStyle = '#000000';

        const tmpData = [
          'Мрр… стройте планы',
          'и мечты — 2020 год будет',
          'положительным для любых начинаний.',
          'Будьте любопытными, развивайтесь',
          'и усердно работайте, чтобы у вас',
          'с котиком была отличная жизнь!'
        ];
        let t = 622;

        tmpData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), t);
          t += 50
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 22){
      const sources = {
        image1: bg,
        image2: marker
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 500, 720, 490);

        ctx.drawImage(images.image2, 50, 550);
        ctx.drawImage(images.image2, 50, 690);
        ctx.drawImage(images.image2, 50, 830);

        ctx.font = '34px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const titleData = [
          'Начните 2020 с хороших привычек',
          'и приятных подарочков для вашего котика,',
          'чтобы он оставался счастливым весь год!'
        ];

        let topTitle = 360;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        ctx.font = '36px rotondacregular';
        ctx.fillStyle = '#000000';

        const tmpData = [
          'Купите или сделайте',
          'котейке новую игрушку'
        ];
        let t = 580;

        tmpData.forEach(e => {
          ctx.fillText(e , 140, t);
          t += 36
        });

        const tmpData2 = [
          'Обновите когтеточку',
          'и мисочки'
        ];
        let t2 = 720;

        tmpData2.forEach(e => {
          ctx.fillText(e , 140, t2);
          t2 += 36
        });

        const tmpData3 = [
          'Оборудуйте котодомик ',
          'с полочками и норками,',
          'если его еще нету'
        ];
        let t3 = 860;

        tmpData3.forEach(e => {
          ctx.fillText(e , 140, t3);
          t3 += 36
        });
        

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 23){
      const sources = {
        image1: bg,
        image2: tudu23,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 110, 510, 500, 500);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';
        const titleData = [
          'Как провести свой день',
          'по-кошачьи'
        ];

        let topTitle = 360;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        ctx.font = '32px rotondacregular';
        ctx.fillStyle = '#ffffff';
        const tmpData = [
          '1. Поиграйте с котиком',
          'хотя бы 15-20 минут'
        ];
        let t = 460;

        tmpData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), t);
          t += 32
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 11){
      const sources = {
        image1: bg,
        image2: g11,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);

        ctx.fillStyle = '#ffffff';
        ctx.fillRect(60, 500, 600, 370);

        ctx.drawImage(images.image2, 110, 535, 196, 288);

        ctx.lineWidth = 30;
        ctx.lineJoin = 'round';
        ctx.beginPath();
        ctx.strokeStyle = "#ffffff"
        ctx.moveTo(75, 500);
        ctx.lineTo(645, 500);
        ctx.lineTo(645, 870);
        ctx.lineTo(75, 870);
        ctx.closePath();
        ctx.stroke();

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';
        const titleData = 'Мемы с котами'

        ctx.fillText(titleData , (canvas.width/2) - (ctx.measureText(titleData).width / 2), 360);

        ctx.font = '32px rotondacregular';
        ctx.fillStyle = '#000000';
        const tmpData = [
          'Какой-то смешной',
          'мемо-кото-текст'
        ];
        let t = 680;

        tmpData.forEach(e => {
          ctx.fillText(e , 340, t);
          t += 32
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 24){
      const sources = {
        image1: bg,
        image2: g24,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 35, 620);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const textString1 = 'Полезные советы';
        const textWidth1 = ctx.measureText(textString1).width;
        ctx.fillText(textString1 , (canvas.width/2) - (textWidth1 / 2), 420);

        ctx.font = '36px rotondacregular';
        ctx.fillStyle = '#ffffff';
        const textString2 = 'Можно ли кошкам';
        const textWidth2 = ctx.measureText(textString2).width;
        ctx.fillText(textString2 , (canvas.width/2) - (textWidth2 / 2), 510);

        const textString3 = 'домашнюю еду?';
        const textWidth3 = ctx.measureText(textString3).width;
        ctx.fillText(textString3 , (canvas.width/2) - (textWidth3 / 2), 550);

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 12){
      const sources = {
        image1: bg,
        image2: g12,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 170, 590, 400, 400);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';
        const titleData = [
          'Любопытные коты',
          'помещаются в любые',
          'коробки'
        ];

        let topTitle = 360;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        ctx.font = '32px rotondacregular';
        ctx.fillStyle = '#ffffff';
        const tmpData2 = [
          'Будьте такими же в 2020 году',
          'и ничто вас не остановит!'
        ];
        let t2 = 520;

        tmpData2.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), t2);
          t2 += 32
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 25){
      const sources = {
        image1: bg,
        image2: gift13Img,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 0, 570, 720, 292);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';
        const titleData = [
          'С Новым 2020 годом, котики!'
        ];

        let topTitle = 400;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 26){
      const sources = {
        image1: bg,
        image2: g26,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 130, 555, 440, 441);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const textString1 = 'Мой котокомикс для';
        const textWidth1 = ctx.measureText(textString1).width;
        ctx.fillText(textString1 , (canvas.width/2) - (textWidth1 / 2), 420);

        const textString2 = 'замурчательного настроения';
        const textWidth2 = ctx.measureText(textString2).width;
        ctx.fillText(textString2 , (canvas.width/2) - (textWidth2 / 2), 470);

        const textString3 = 'в 2020 году';
        const textWidth3 = ctx.measureText(textString3).width;
        ctx.fillText(textString3 , (canvas.width/2) - (textWidth3 / 2), 520);

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 14){
      const sources = {
        image1: bg,
        image2: g14,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 85, 478, 550, 524);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';
        const titleData = [
          'Чесать кота — это',
          'исКУСЬтво!'
        ];

        let topTitle = 400;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 37){
      if(gift37){
        const sources = {
          image1: bg,
          image2: gift37,
        };
  
        loadImages(sources, function(images) {
          ctx.drawImage(images.image1, 0, 0);
          ctx.drawImage(images.image2, 60, 450, 600, 550);
  
          ctx.font = '48px rotondacbold';
          ctx.fillStyle = '#fbdc36';
          const titleData = [
            'Моя котооткрытка',
            'на 2020 год!'
          ];
  
          let topTitle = 370;
          titleData.forEach(e => {
            ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
            topTitle += 50;
          });
  
          const dataURL = canvas.toDataURL('image/jpeg', 1.0);
          submitImage(dataURL, userId, day, vendor);
        });
      }
    }

    /* вторая декада */


    /* третья декада */
    if(index == 40){
      const sources = {
        image1: bg
      };

      loadImages(sources, images => {
        ctx.drawImage(images.image1, 0, 0);
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 520, 720, 440);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const titleData = [
          'Вот насколько',
          'замурчательным будет мой',
          'год!'
        ];

        let topTitle = 380;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        ctx.font = '38px rotondacregular';
        ctx.fillStyle = '#000000';

        const tmpData = [
          'Чтобы быть бодрыми',
          'и здоровыми в этом году,',
          'почаще расслабляйтесь',
          'и медитируйте, поглаживая',
          'котейку. Это самое',
          'замурчательное успокоительное!'
        ];
        let t = 622;

        tmpData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), t);
          t += 50
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 41){
      const sources = {
        image1: bg,
        image2: marker
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 500, 720, 490);

        ctx.drawImage(images.image2, 50, 550);
        ctx.drawImage(images.image2, 50, 690);
        ctx.drawImage(images.image2, 50, 830);

        ctx.font = '34px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const titleData = [
          'Начните 2020 с хороших привычек',
          'и приятных подарочков для вашего котика,',
          'чтобы он оставался счастливым весь год!'
        ];

        let topTitle = 360;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        ctx.font = '36px rotondacregular';
        ctx.fillStyle = '#000000';

        const tmpData = [
          'Выберитесь в интересное место.',
          'Только ненадолго,',
          'а то котик скучает.'
        ];
        let t = 580;

        tmpData.forEach(e => {
          ctx.fillText(e , 140, t);
          t += 36
        });

        const tmpData2 = [
          'Смастерите игрушку для',
          'любимца и, возможно, он',
          'перестанет царапать диван.'
        ];
        let t2 = 720;

        tmpData2.forEach(e => {
          ctx.fillText(e , 140, t2);
          t2 += 36
        });

        const tmpData3 = [
          'Читайте книжки в уютной',
          'обстановке с пледом,',
          'какао и котом.'
        ];
        let t3 = 860;

        tmpData3.forEach(e => {
          ctx.fillText(e , 140, t3);
          t3 += 36
        });
        

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 42){
      const sources = {
        image1: bg,
        image2: g42,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 35, 620);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const textString1 = 'Полезные советы';
        const textWidth1 = ctx.measureText(textString1).width;
        ctx.fillText(textString1 , (canvas.width/2) - (textWidth1 / 2), 420);

        ctx.font = '36px rotondacregular';
        ctx.fillStyle = '#ffffff';
        const textString2 = 'Почему котик';
        const textWidth2 = ctx.measureText(textString2).width;
        ctx.fillText(textString2 , (canvas.width/2) - (textWidth2 / 2), 510);

        const textString3 = 'мяукает?';
        const textWidth3 = ctx.measureText(textString3).width;
        ctx.fillText(textString3 , (canvas.width/2) - (textWidth3 / 2), 550);

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }


    if(index == 15){
      const sources = {
        image1: bg,
        image2: g15,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 85, 445, 550, 550);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';
        const titleData = [
          'Я уже нашел котика на',
          'картинке! А вы сможете?'
        ];

        let topTitle = 360;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 16){
      const sources = {
        image1: bg,
      };

      const array = [
        [
          'Ваш новый год точно будет',
          'замечательным! Но вот',
          'замурчательным… не уверены.',
          'Кажется, вас не очень привлекают',
          'котики. Может быть, вы',
          'просто еще не нашли того самого,',
          'который украдет ваше сердечко?',
        ],
        [
          'Ваш новый год будет теплым,',
          'мягким и пушистым. Прямо,',
          'как ваши кошачьи друзья!',
          'Не забудьте подарить им',
          'подарок в этот праздник.',
          'Как насчет новогоднего',
          'WHISKAS® "Праздничный микс"? 😉',
        ],
        [
          'Больше Мяу, мяу, мяу!',
          'У вас будет замурчательным',
          'не только Новый Год, но и вообще',
          'каждый день, ведь вы настоящий',
          'котофанат! Желаем вам и вашем',
          'пушистикам отлично провести',
          'праздничные дни.',
        ]
      ]

      loadImages(sources, images => {
        ctx.drawImage(images.image1, 0, 0);
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 520, 720, 440);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const titleData = [
          'Насколько замурчательным',
          'будет ваш Новый год?'
        ];

        let topTitle = 380;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50
        });

        ctx.font = '40px rotondacregular';
        ctx.fillStyle = '#000000';

        const tmpData = array[gift16Index];
        let t = 603;

        tmpData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), t);
          t += 50
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }


    if(index == 43){
      const sources = {
        image1: bg,
        image2: g43,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 130, 555, 440, 441);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const textString1 = 'Мой котокомикс для';
        const textWidth1 = ctx.measureText(textString1).width;
        ctx.fillText(textString1 , (canvas.width/2) - (textWidth1 / 2), 420);

        const textString2 = 'замурчательного настроения';
        const textWidth2 = ctx.measureText(textString2).width;
        ctx.fillText(textString2 , (canvas.width/2) - (textWidth2 / 2), 470);

        const textString3 = 'в 2020 году';
        const textWidth3 = ctx.measureText(textString3).width;
        ctx.fillText(textString3 , (canvas.width/2) - (textWidth3 / 2), 520);

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }


    if(index == 47){
      if(gift47){
        const sources = {
          image1: bg,
          image2: gift47,
        };
  
        loadImages(sources, function(images) {
          ctx.drawImage(images.image1, 0, 0);
          ctx.drawImage(images.image2, 60, 450, 600, 550);
  
          ctx.font = '48px rotondacbold';
          ctx.fillStyle = '#fbdc36';
          const titleData = [
            'Моя которамка',
            'на 2020 год!'
          ];
  
          let topTitle = 370;
          titleData.forEach(e => {
            ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
            topTitle += 50;
          });
  
          const dataURL = canvas.toDataURL('image/jpeg', 1.0);
          submitImage(dataURL, userId, day, vendor);
        });
      }
    }

    if(index == 44){
      const sources = {
        image1: bg,
        image2: g44,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 85, 450, 550, 550);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const textString1 = 'Как сделать игрушку для кота';
        const textWidth1 = ctx.measureText(textString1).width;
        ctx.fillText(textString1 , (canvas.width/2) - (textWidth1 / 2), 420);

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }


    if(index == 46){

      if(g46Img){
        const sources = {
          image1: bg,
          image2: g46Img,
        };
  
        loadImages(sources, function(images) {
          ctx.drawImage(images.image1, 0, 0);
    
          if(g46Name){
            ctx.drawImage(images.image2, 35, 650);
    
            ctx.font = '48px rotondacbold';
            ctx.fillStyle = '#fbdc36';
            const textString1 = 'Ваша кошачья порода';
            const textWidth1 = ctx.measureText(textString1).width;
            ctx.fillText(textString1 , (canvas.width/2) - (textWidth1 / 2), 420);
      
            ctx.font = '70px rotondacbold';
            ctx.fillStyle = '#ffffff';
            const textString2 = g46Name;
            const textWidth2 = ctx.measureText(textString2).width;
            ctx.fillText(textString2 , (canvas.width/2) - (textWidth2 / 2), 570);
    
            const dataURL = canvas.toDataURL('image/jpeg', 1.0);
            submitImage(dataURL, userId, day, vendor);
          }
        });
      }
    }

    if(index == 48){
      const sources = {
        image1: bg,
        image2: g48,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 35, 620);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';
        const titleData = [
          'Как мяукают коты',
          'в разных странах'
        ];

        let topTitle = 370;
        titleData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topTitle);
          topTitle += 50;
        });

        ctx.font = '36px rotondacregular';
        ctx.fillStyle = '#ffffff';

        const textData = [
          'Узнайте, как сказать «Мяу»,',
          'чтобы котики-иностранцы вас поняли.'
        ];

        let topText = 515;
        textData.forEach(e => {
          ctx.fillText(e , (canvas.width/2) - (ctx.measureText(e).width / 2), topText);
          topText += 35;
        });

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    
    if(index == 50){
      const sources = {
        image1: bg,
        image2: g51,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 60, 397, 600, 601);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const textString1 = 'С Новым годом!';
        const textWidth1 = ctx.measureText(textString1).width;
        ctx.fillText(textString1 , (canvas.width/2) - (textWidth1 / 2), 370);

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }

    if(index == 51){
      const sources = {
        image1: bg,
        image2: g50,
      };

      loadImages(sources, function(images) {
        ctx.drawImage(images.image1, 0, 0);
        ctx.drawImage(images.image2, 130, 555, 440, 441);

        ctx.font = '48px rotondacbold';
        ctx.fillStyle = '#fbdc36';

        const textString1 = 'Мой котокомикс для';
        const textWidth1 = ctx.measureText(textString1).width;
        ctx.fillText(textString1 , (canvas.width/2) - (textWidth1 / 2), 420);

        const textString2 = 'замурчательного настроения';
        const textWidth2 = ctx.measureText(textString2).width;
        ctx.fillText(textString2 , (canvas.width/2) - (textWidth2 / 2), 470);

        const textString3 = 'в 2020 году';
        const textWidth3 = ctx.measureText(textString3).width;
        ctx.fillText(textString3 , (canvas.width/2) - (textWidth3 / 2), 520);

        const dataURL = canvas.toDataURL('image/jpeg', 1.0);
        submitImage(dataURL, userId, day, vendor);
      });
    }
    /* третья декада */



  }

  let attr = 0;

  if(scope.indexOf('stories') !== -1 || scope.indexOf('photos') !== -1){
    attr = 1;
  }

  if(scope.indexOf('stories') !== -1 && scope.indexOf('photos') !== -1){
    attr = 2;
  }

  return (
    <div className="sharing-wrap" data-count={attr}>
      <canvas id="sharing-generator"/>

      { (token && scope.indexOf('stories') !== -1) && (
        <div
          className={stateHystory.status === 'load' ? 'share-button disabled' : 'share-button'}
          onClick={shareImage}
        >
          {stateHystory.status === 'load' ? 'Пожалуйста, ждите' : 'Поделиться в истории'}
        </div>
      )}

      {(token && scope.indexOf('photos') !== -1) && (
        <div
          className={stateShare.status === 'load' ? 'share-button disabled' : 'share-button'}
          onClick={() => shareImage('photo')}
        >
          {stateShare.status === 'load' ? 'Пожалуйста, ждите' : 'Поделиться на стене'}
        </div>
      )}
      
      { (stateHystory.status === 'error' || stateHystory.status === 'complete' ) && (
        <Snackbar onClose={() => hystoryLoad(false)}>
          {stateHystory.status === "error" ? <>Что-то&nbsp;пошло&nbsp;не&nbsp;так. Пожалуйста,&nbsp;повторите&nbsp;попытку позже.</> : <>Ваша&nbsp;история успешно&nbsp;опубликована,&nbsp;мур</>}
        </Snackbar>
      )}

      { (stateShare.status === 'error' || stateShare.status === 'complete' ) && (
        <Snackbar onClose={() => shareLoad(false)}>
          {stateShare.status === "error" ? <>Что-то&nbsp;пошло&nbsp;не&nbsp;так. Пожалуйста,&nbsp;повторите&nbsp;попытку позже.</> : <>Ваша&nbsp;запись успешно&nbsp;опубликована,&nbsp;мур</>}
        </Snackbar>
      )}
    </div>
  );
}

export default reduxConnect(state => ({
  stateHystory: state.hystory,
  stateShare: state.share,
}), { hystoryLoad, shareLoad })(ShareButton);
