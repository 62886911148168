import React, { useEffect } from 'react';
import PageHead from '../PageHead';
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';
import ButtonToBottom from '../../components/ButtonToBottom';

import ShareButton from '../ShareButton';


import img1 from '../../img/gifts/g4_img1n.jpg';
import img2 from '../../img/gifts/g4_img2n.jpg';
import img3 from '../../img/gifts/g4_img3n.jpg';
import img4 from '../../img/gifts/g4_img4n.jpg';
import img5 from '../../img/gifts/g4_img5n.jpg';

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

const Gift4 = ({token, index, goHome, goBot, mobile, gifts, userId, scope}) => {
  
  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
            {mobile ? (
              <>
                <div className="gift-title">Что подарить котику на Новый год?</div>
                Составили свой рейтинг подарков для&nbsp;котейки, которым он точно будет рад.
                {scope != '' && <ButtonToBottom />}
              </>
            ) : (
              <div className="gift__head-with-buttons">
                <div>
                  <div className="gift-title">Что подарить котику<br />на Новый год?</div>
                  Составили свой рейтинг подарков для&nbsp;котейки,<br />которым он точно будет рад.
                </div>
                <div>
                  <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                </div>
              </div>
            )}
  
          </div>
          
          <div className="gift__dsc gift__dsc_gift4">
            <div className="dgift__dsc dgift__dsc-center">
              {!mobile && (
                <div>
                  <img src={img1} width="291" height="319" />
                </div>
              )}
              <div>
                <div className="gift4__title">Игровой комплекс</div>
                <div className="gift4__text">Котейке важно, чтобы у&nbsp;него было свое место. А&nbsp;игровой комплекс&nbsp;—&nbsp;это настоящий замок, в&nbsp;котором он будет чувствовать себя королем!</div>
              </div>
              {mobile && (
                <div>
                  <img src={img1} width="291" height="319" />
                </div>
              )}
            </div>
          </div>
  
          <div className="gift__dsc gift__dsc_gift4">
            <div className="dgift__dsc dgift__dsc-center">
              {!mobile && (
                <div>
                  <img src={img2} width="291" height="319" />
                </div>
              )}
              <div>
                <div className="gift4__title">Автоматическая кормушка и&nbsp;поилка</div>
                <div className="gift4__text">Если вас часто нет дома, не&nbsp;оставляйте любимца голодным&nbsp;—&nbsp;купите ему котопоилку и&nbsp;котокормушку. Не&nbsp;забудьте подобрать к&nbsp;ним вкусный и&nbsp;здоровый корм на&nbsp;сайте WHISKAS®.</div>
              </div>
              {mobile && (
                <div>
                  <img src={img2} width="291" height="319" />
                </div>
              )}
            </div>
          </div>
  
          <div className="gift__dsc gift__dsc_gift4">
            <div className="dgift__dsc dgift__dsc-center">
              {!mobile && (
                <div>
                  <img src={img3} width="291" height="319" />
                </div>
              )}
              <div>
                <div className="gift4__title">Лежанка на присосках</div>
                <div className="gift4__text">Котики очень любопытны и&nbsp;часто наблюдают за тем, что происходит на улице. Прикрепите эту лежанку к&nbsp;окну, чтобы пушистый друг мог мяукать на&nbsp;птиц с&nbsp;комфортом.</div>
              </div>
              {mobile && (
                <div>
                  <img src={img3} width="291" height="319" />
                </div>
              )}
            </div>
          </div>
  
          <div className="gift__dsc gift__dsc_gift4">
            <div className="dgift__dsc dgift__dsc-center">
              {!mobile && (
                <div>
                  <img src={img4} width="291" height="319" />
                </div>
              )}
              <div>
                <div className="gift4__title">Когтеточка с&nbsp;игрушкой</div>
                <div className="gift4__text">Если кот очень любит точить царапки, сделайте этот процесс еще увлекательнее. Обычные когтеточки&nbsp;—&nbsp;скукота, когтеточки с&nbsp;игрушкой&nbsp;—&nbsp;для крутого кота.</div>
              </div>
              {mobile && (
                <div>
                  <img src={img4} width="291" height="319" />
                </div>
              )}
            </div>
          </div>
  
          <div className="gift__dsc gift__dsc_gift4">
            <div className="dgift__dsc dgift__dsc-center">
              {!mobile && (
                <div>
                  <img src={img5} width="291" height="319" />
                </div>
              )}
              <div>
                <div className="gift4__title">Пирамидка с&nbsp;шариками</div>
                <div className="gift4__text">Три трека с&nbsp;шариками, которые катаются по&nbsp;кругу от&nbsp;удара лапкой. Котейкам особенно нравится играть с&nbsp;ней по ночам&nbsp;—&nbsp;они уверены, что звуки от&nbsp;шариков помогают вам лучше спать.</div>
              </div>
              {mobile && (
                <div>
                  <img src={img5} width="291" height="319" />
                </div>
              )}
            </div>
          </div>
  
  
        </div>
  
        {mobile && <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />}
      </div>
  
      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift4);
