import React from 'react';
import { connect } from 'react-redux';
import { popupStatus, popupPostcardStatus, popupGiftStatus } from '../components/store/actions';

import img from '../img/popup.jpg'

const Rules = ({popupStatus, popup, mobile, popuppostcard, popupPostcardStatus, popupGiftStatus, popupgift}) => {

  return (
    <>
      <div className={popup.status ? 'popup active' : 'popup'} onClick={() => popupStatus(false)}>
        <div className="popup__in">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.75 2.75L19.6995 19.6995" stroke="#BA0184" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.25 2.75L2.30048 19.6995" stroke="#BA0184" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>

          {!mobile && (
            <div>
              <img src={img} />
            </div>
          )}
          <div>
            {mobile && <div className="popup__title">Правила акции</div>}
            <ul>
              <li>Открывайте ячейки каждый день в течение всего декабря.</li>
              <li>Если первый раз вы зайдете в календарь после 1-го декабря, то вам будут доступны все предыдущие ячейки.</li>
              <li>Если вы уже заходили в календарь,
              но пропустили несколько дней, то вы сможете открыть не более 3-х ячеек из числа всех пропущенных в календаре.</li>
              <li>Пользователи, которые заходят в календарь
              и открывают ячейки каждый день
              на протяжении 10 дней подряд, могут поучаствовать в розыгрыше дополнительных подарков от бренда.</li>
              <li>В один из дней в качестве подарка открывается возможность получить подарок ВКонтакте. Количество подарков ВКонтакте ограничено.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className={popuppostcard.status ? 'popup popup_photo active' : 'popup popup_photo'} onClick={() => popupPostcardStatus(false)}>
        <div className="popup__in">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.75 2.75L19.6995 19.6995" stroke="#BA0184" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.25 2.75L2.30048 19.6995" stroke="#BA0184" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <div>
            {mobile && <div className="popup__title">Правила загрузки фотографий</div>}
            <p>Фото и Текст должны соответствовать следующим требованиям:</p>
            <ul>
              <li>Фото должно содержать изображение домашнего животного – кота</li>
              <li>Формат Фото jpg, jpeg, png</li>
              <li>Автором и единственным правообладателем Фото и Текста является Участник Акции</li>
              <li>Участник Акции является владельцем домашнего животного, изображенного на Фото</li>
            </ul>
            <p>Не допускаются к участию в Акции Открытки:</p>
            <ul>
              <li>содержащие рекламу товаров и услуг третьих лиц</li>
              <li>содержащие изображения физических лиц</li>
              <li>содержащие упоминания конкурентов Организатора</li>
              <li>пропагандирующие жестокость и насилие</li>
              <li>эротического или порнографического характера</li>
              <li>нарушающие общепринятые морально-этические нормы</li>
              <li>содержащие изображение процесса курения/употребления спиртных напитков/наркотических веществ, либо
              иным образом пропагандирующие курение/употребление спиртных напитков/наркотических веществ</li>
              <li>содержащие экстремистские материалы</li>
              <li>содержание которых может быть признано клеветническим, оскорбительным, или иным образом порочит
              честь, достоинство и деловую репутацию любых третьих лиц</li>
              <li>иным образом нарушающие требования действующего законодательства Российской Федерации, в том числе требования, установленные Федеральным законом № 38-ФЗ «О Рекламе», Федеральным законом №436-ФЗ «О защите детей от информации, причиняющей вред их здоровью и развитию»</li>
              <li>которые, на усмотрение Организатора не соответствуют Акции</li>
            </ul>
          </div>
        </div>
      </div>

      <div className={popupgift.status ? 'popup popup_gift active' : 'popup popup_gift'} onClick={() => popupGiftStatus(false)}>
        <div className="popup__in">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.75 2.75L19.6995 19.6995" stroke="#BA0184" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.25 2.75L2.30048 19.6995" stroke="#BA0184" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <div>
            <p>Вы открыли доступное количество ячеек, приходите завтра</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  state => ({
    popup: state.popup,
    popuppostcard: state.popuppostcard,
    popupgift: state.popupgift
  }),
  { popupStatus, popupPostcardStatus, popupGiftStatus }
)(Rules);
