import 'core-js/features/map';
import 'core-js/features/set';
import React from 'react';
import ReactDOM from 'react-dom';
import connect from '@vkontakte/vk-connect';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose} from 'redux';
import { Provider } from 'react-redux';
import mVKMiniAppsScrollHelper from '@vkontakte/mvk-mini-apps-scroll-helper';

import reducer from './components/store/reducer';
import App from './App';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));

connect.send('VKWebAppInit');

const root = document.getElementById('root');
mVKMiniAppsScrollHelper(root)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
root);
