import React, {useState, useEffect} from 'react';
import axios from 'axios';
import PageHead from '../PageHead';
import Confetti from 'react-confetti'
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';
import ShareButton from '../ShareButton';

import gift from './../../img/gifts/gift_native2.png';
import { DOMAIN, GROUP_ID, APP_ID } from '../../constants';

import img_d from './../../img/gifts/Ng_otkrytka_1.png';
import img_m from './../../img/gifts/Ng_otkrytka_2.png';

import BotSubscribe from '../BotSubscribe';

import connect from '@vkontakte/vk-connect';

const Gift8 = ({index, goHome, goBot, fetchedUser, mobile, gifts, botStatus, userId, token, scope})  => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);


  const clickBot = () => {
    if(userId){
      connect.send("VKWebAppAllowMessagesFromGroup", {"group_id": GROUP_ID, "key": userId});
      setStatus(null);
    }
  }

  const sendGift = () => {

    const searchParams = new URLSearchParams(window.location.search);
    const paramsObj = {
      vk_access_token_settings: searchParams.get('vk_access_token_settings'),
      vk_app_id: searchParams.get('vk_app_id'),
      vk_are_notifications_enabled: searchParams.get('vk_are_notifications_enabled'),
      vk_is_app_user: searchParams.get('vk_is_app_user'),
      vk_is_favorite: searchParams.get('vk_is_favorite'),
      vk_language: searchParams.get('vk_language'),
      vk_platform: searchParams.get('vk_platform'),
      vk_ref: searchParams.get('vk_ref'),
      vk_user_id: searchParams.get('vk_user_id'),
      sign: searchParams.get('sign'),
    }

    axios.get(`${DOMAIN}/api/gift.php`, {
      params: {
        day: gifts.day,
        ...paramsObj
      }
    })
    .then(function (response) {
      if(response.data.error){
        //setError(response.data.error);
        setStatus('ok');
      }

      if(response.data.id){
        setStatus('ok');

        axios.get(`${DOMAIN}/api/send_gift.php`, {
          params: {
            ...paramsObj
          }
        })
        .then(function (response) {
          if(response.data.status == 'subscribe'){
            setStatus('nobot');
          }
          if(response.data.status == 'duplicate'){
            setStatus('ok');
          }
          if(response.data.status == 'left'){
            setStatus('end');
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      }

      if(response.data.id == 0){
        setStatus('end');
      }
    })
    .catch(function (error) {
      //console.log(error);
    }); 
  }

  if(mobile){
    return (
      <div className="wrap">
        <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
        <div className="page page_gift">
          <div className={`gift gift${index}`}>
            <div className="gift__head">
              <div className="gift__head-num">{gifts.day}</div>
              <div className="gift-title">Подарок ВКонтакте</div>
              <img src={gift} />

              {status === null && !error && (
                <>
                  Суперновость! Только сегодня доступен эксклюзивный подарок, который вы можете получить для себя и&nbsp;подарить своим друзьям! Нажмите кнопку «получить», и&nbsp;чат-бот вышлет его в&nbsp;личные сообщения. Поспешите, подарков не&nbsp;так много!
                </>
              )}

              {status === 'end' && !error && (
                <>
                  Жаль, что вы не&nbsp;зашли раньше, этот подарок уже закончился! 
                  Не расстраивайтесь, лучше ловите праздничную котооткрытку!
                </>
              )}

              {status === 'ok' && !error && (
                <>
                  Подарок уже в&nbsp;личных сообщениях, скорее вручайте его друзьям.
                </>
              )}

              {error && <>{error}</>}

              {status === 'end' && <img src={img_m} className="gift8mobileimg" />}

              { (status === 'nobot' && botStatus == '-1' ) && (
                <>
                  Дарю вам самый замурчательный подарок! Вы можете подарить его своим друзьям 3&nbsp;раза совершенно бесплатно.
                  Не&nbsp;забудьте заходить в приложение каждый день, чтобы не&nbsp;пропустить новые подарки, а&nbsp;я&nbsp;буду о&nbsp;них напоминать. Если захотите отписаться, напишите «Отписаться» или «Стоп».
                </>
              )}

            </div>
              <div className="gift8-canvas">
              <Confetti
                gravity={0.03}
                numberOfPieces={100}
                width={window.innerWidth}
                height={window.innerHeight}
                colors={['#fffbed', '#e8cf7d', '#e4c658', '#d49538', '#e6da50', '#e6d17e', '#be914e', '#dac55c', '#bd9b3a']}
              />
              </div>
            {status === null && !error && <div className="share-button" onClick={sendGift}>Хочу подарок ВКонтакте!</div>}
            { (status === 'end' && botStatus == '-1' ) && <BotSubscribe userId={fetchedUser.id }>Подписаться на чат - бота</BotSubscribe>}
            { (status === 'nobot' && botStatus == '-1' ) && <div className="share-button" onClick={clickBot}>Подписаться на чат - бота</div>}
            {status === 'ok' && <a href={`https://${mobile ? 'm.' : ''}vk.com/im?sel=-${GROUP_ID}`} target="_blank" className="share-button">Перейти в чат-бот</a>}

            {status === 'end' && (
              <ShareButton index={index} token={token} day={gifts.day} userId={userId} gift8Img={img_d} scope={scope} />
            )}
          </div>
        </div>
        <RelesLnk />
      </div>
    );
  }

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index} desctope`}>
          <div className="gift__head">
            <div className="gift__head-with-buttons">
              <div>
              <div className="gift__head-num">{gifts.day}</div>
                <div className="gift-title">Подарок ВКонтакте</div>

                {status === null && !error && (
                  <>
                    Суперновость! Только сегодня доступен эксклюзивный подарок, который вы можете получить для себя и&nbsp;подарить своим друзьям! Нажмите кнопку «получить», и&nbsp;чат-бот вышлет его в&nbsp;личные сообщения. Поспешите, подарков не&nbsp;так много!
                  </>
                )}

                {status === 'end' && !error && (
                  <>
                    Жаль, что вы не&nbsp;зашли раньше, этот подарок уже закончился! 
                    Не расстраивайтесь, лучше ловите праздничную котооткрытку!
                  </>
                )}

                {status === 'ok' && !error && (
                  <>
                    Подарок уже в&nbsp;личных сообщениях, скорее вручайте его друзьям.
                  </>
                )}

              {error && <>{error}</>}

              {status === 'nobot' && botStatus == '-1' && (
                <>
                  Дарю вам самый замурчательный подарок! Вы можете подарить его своим друзьям 3&nbsp;раза совершенно бесплатно.
                  Не&nbsp;забудьте заходить в приложение каждый день, чтобы не&nbsp;пропустить новые подарки, а&nbsp;я&nbsp;буду о&nbsp;них напоминать. Если захотите отписаться, напишите «Отписаться» или «Стоп».
                </>
              )}

              </div>
              {status === 'end' && (
                <div>
                  <ShareButton index={index} token={token} day={gifts.day} userId={userId} gift8Img={img_d} scope={scope} />
                </div>
              )}
            </div>
          </div>
          
          <div className="gift8-canvas">
            <Confetti
              gravity={0.03}
              numberOfPieces={100}
              width={window.innerWidth}
              height={window.innerHeight}
              colors={['#fffbed', '#e8cf7d', '#e4c658', '#d49538', '#e6da50', '#e6d17e', '#be914e', '#dac55c', '#bd9b3a']}
            />
          </div>

          <div className="gift__dsc gift__dsc_img">
            <img src={gift} />

            {status === 'end' && <img src={img_d} className="gift8-postcard" />}
            {status === null && !error && <div className="share-button" onClick={sendGift}>Хочу подарок ВКонтакте!</div>}
            { (status === 'end' && botStatus == '-1' ) && <BotSubscribe userId={fetchedUser.id }>Подписаться на чат - бота</BotSubscribe>}
            { (status === 'nobot' && botStatus == '-1' ) && <div className="share-button" onClick={clickBot}>Подписаться на чат - бота</div>}
            {status === 'ok' && <a href={`https://${mobile ? 'm.' : ''}vk.com/im?sel=-${GROUP_ID}`} target="_blank" className="share-button">Перейти в чат-бот</a>}
          </div>

        </div>
      </div>
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift8);
