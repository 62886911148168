import React, { useState, useEffect } from 'react';
import PageHead from '../PageHead';
import ShareButton from '../ShareButton';
import { connect as reduxConnect } from 'react-redux';

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

import img1 from '../../img/gifts/test2_ico.svg';

import meykun_d from '../../img/gifts/g46/meykun_d.jpg';
import meykun_m from '../../img/gifts/g46/meykun_m.jpg';
import sib_d from '../../img/gifts/g46/sib_d.jpg';
import sib_m from '../../img/gifts/g46/sib_m.jpg';
import sia_d from '../../img/gifts/g46/sia_d.jpg';
import sia_m from '../../img/gifts/g46/sia_m.jpg';

import RelesLnk from '../../components/RelesLnk';

const Gift46 = ({index, goHome, goBot, token, mobile, gifts, userId, scope})  => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  const [step, setStep] = useState(1);
  const [value, setValue] = useState({
    'a': 0,
    'b': 0,
    'c': 0
  });
  const max = 4;
  const rand = Math.round(Math.random() * 2);

  const result = () => {
    const arrayText = [
      'У кошек этой породы суровый вид. Несмотря на&nbsp;это они добродушны и&nbsp;ласковы, хотя и&nbsp;не&nbsp;очень общительны. А еще Мейн-куны прирожденные охотники и&nbsp;не&nbsp;любят сидеть в&nbsp;четырех стенах.',
      'Эти кошки очень любят свою семью и&nbsp;настороженно относятся к&nbsp;незнакомцам. Их&nbsp;доверие надо заслужить. Также Сибирские коты отличаются гордостью и&nbsp;сообразительностью.',
      'Сиамские котейки невероятно активны, любвеобильны и&nbsp;общительны. При этом они ревнивы, хотят всегда быть в&nbsp;центре внимания и&nbsp;не терпят холодного отношения к&nbsp;себе.'
    ]

    const arrayTitle = [
      'Мейн-кун',
      'Сибирская!',
      'Сиамская!'
    ]

    const arrayImgs = [
      [meykun_d, meykun_m],
      [sib_d, sib_m],
      [sia_d, sia_m]
    ]

    return [
      arrayText[getIndex()],
      arrayTitle[getIndex()],
      arrayImgs[getIndex()]
    ];
  }

  const getIndex = () => {
    if(value['a'] >= 2){
      return 0;
    }
    if(value['b'] >= 2){
      return 1;
    }
    if(value['c'] >= 2){
      return 2;
    }

    return rand;
  }

  const next = v => {
    if(v){
      const obj = {...value};
      obj[v] = obj[v] + 1
      setValue(obj);
    };
    setStep(step + 1);

    if(step === 3){
      window.scrollTo(0, 0);
    }
  }

  const back = () => {
    setStep(1);
    setValue({
      'a': 0,
      'b': 0,
      'c': 0
    });
    window.scrollTo(0, 0);
  }

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
        <div className="page page_gift">
          <div className={`gift gift${index}`}>

          <div className="gift-f">
            <div>
              {mobile ? (
                <div className="gift__head gift6-result">
                  <div className="gift6-result__in">
                    <div className="gift__head-num">{gifts.day}</div>
                    {step != max ? (
                      <>
                        <div className="gift-title">Какой была бы&nbsp;ваша кошачья порода?</div>
                        Узнайте, какая кошачья порода идеально подойдет вашему характеру.
                      </>
                    ) : (
                      <>
                      <div className="gift46__mobile">
                        <div className="gift-title">Какой была бы&nbsp;ваша кошачья порода?</div>
                        <div className="g6-dsc">Ваша кошачья порода</div>
                        <div className="gift-title">{result()[1]}</div>
                        <p dangerouslySetInnerHTML={{ __html: result()[0] }} />
                      </div>
                      </>
                    )}
                  </div>
                  {step == max && <img src={result()[2][1]} />}
                </div>
              ) : (
                <div className="gift__head">
                  <div className="gift__head-num">{gifts.day}</div>
                  {step != max ? (
                    <>
                      <div className="gift-title">Какой была бы&nbsp;ваша кошачья порода?</div>
                      Узнайте, какая кошачья порода идеально подойдет вашему характеру.
                    </>
                  ) : (
                    <>
                      <div className="g6-dsc">Ваша кошачья порода</div>
                      <div className="gift-title">{result()[1]}</div>
                    </>
                  )}
                </div>
              )}
            </div>
            <div>
              {step === 1 && (
                <div className="gift__dsc gift__dsc_test">
                  {!mobile && <img src={img1} />}
                  <div className="gift__test-title">{step}/3</div>
                  <div className="gift__testquestion">Какие качества вам подходят?</div>
                  <div className="gift__test-buttons">
                    <div className="gift__test-button" onClick={() => next('a')}>Доброта и&nbsp;спокойствие</div>
                    <div className="gift__test-button" onClick={() => next('b')}>Независимость и&nbsp;ум</div>
                    <div className="gift__test-button" onClick={() => next('c')}>Активность и&nbsp;игривость</div>
                  </div>
                </div>
              )}

              {step === 2 && (
                <div className="gift__dsc gift__dsc_test">
                  {!mobile && <img src={img1} />}
                  <div className="gift__test-title">{step}/3</div>
                  <div className="gift__testquestion">Любите ли вы общаться?</div>
                  <div className="gift__test-buttons">
                    <div className="gift__test-button" onClick={() => next('a')}>Мне и&nbsp;без этого хорошо</div>
                    <div className="gift__test-button" onClick={() => next('b')}>Да, но&nbsp;только с&nbsp;близкими</div>
                    <div className="gift__test-button" onClick={() => next('c')}>Жить не&nbsp;могу без&nbsp;общения!</div>
                  </div>
                </div>
              )}

              {step === 3 && (
                <div className="gift__dsc gift__dsc_test">
                  {!mobile && <img src={img1} />}
                  <div className="gift__test-title">{step}/3</div>
                  <div className="gift__testquestion">Какой отдых вам по душе?</div>
                  <div className="gift__test-buttons">
                    <div className="gift__test-button" onClick={() => next('a')}>Морской круиз&nbsp;—&nbsp;мечта!</div>
                    <div className="gift__test-button" onClick={() => next('b')}>Отведите меня в&nbsp;SPA-салон</div>
                    <div className="gift__test-button" onClick={() => next('c')}>Я&nbsp;выбираю шумную вечеринку</div>
                  </div>
                </div>
              )}

              { (!mobile && step === max) && (
                <div className="gift__dsc">

                  <div className="dgift__dsc dgift__dsc-center">
                    <div>
                      <img src={result()[2][0]} width="291" height="319" />
                    </div>
                    <div>
                      <p dangerouslySetInnerHTML={{ __html: result()[0]}} />
                      <div className="share-button" onClick={() => back()}>Пройти тест заново</div>
                      <ShareButton g46Name={result()[1]} g46Img={result()[2][1]} index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                    </div>
                  </div>
                </div>
              )}

              { (mobile && step === max) && (
                <>
                  <div className="share-button" onClick={() => back()}>Пройти тест заново</div>
                  <ShareButton g46Name={result()[1]} g46Img={result()[2][1]} index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                </>
              )}
            </div>
          </div>


        </div>
      </div>

      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift46);
