import React from 'react';
import $ from 'jquery';

const ButtonToBottom = () => {
  const scrollTo = () => {
    $('html, body').animate({scrollTop: document.body.clientHeight + 100}, 800);
  }

  return(
    <div className="share-button big button-scroll"onClick={scrollTo}>Поделиться</div>
  )
}

export default ButtonToBottom;