import React, { useState, useEffect } from 'react';
import PageHead from '../PageHead';
import ShareButton from '../ShareButton';
import Slider from "react-slick";
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';

import slide1d from '../../img/postcards/desktop_kot_i_elka.png';
import slide1m from '../../img/postcards/otkrytki_kot_i_elka.png';
import slide2d from '../../img/postcards/desktop_kot_i_igrushki.png';
import slide2m from '../../img/postcards/otkrytki_kot_i_igrushki.png';
import slide3d from '../../img/postcards/desktop_kot_i_podarki.png';
import slide3m from '../../img/postcards/otkrytka_kot_i_podarki.png';
import slide4d from '../../img/postcards/desktop_kot_i_snezhinki.png';
import slide4m from '../../img/postcards/otkrytki_kot_i_snezhinki.png';
import slide5d from '../../img/postcards/desktop_kot_i_vetki.png';
import slide5m from '../../img/postcards/otkrytka_mobayl_kot_i_vetki.png';

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

const Gift13 = ({index, goHome, goBot, token, mobile, gifts, userId, scope})  => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  const [current, setCurrent] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: index => setCurrent(index)
  };

  const array = [
    slide1d,
    slide2d,
    slide3d,
    slide4d,
    slide5d
  ]

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
        <div className="page page_gift">
          <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>

            {mobile ? (
              <>
                <div className="gift-title">С Новым 2020 котогодом!</div>
                Выберите праздничную котооткрытку и&nbsp;поздравьте друзей с&nbsp;наступающим 2020&nbsp;годом!
              </>
            ) : (
              <>
                <div className="gift__head-with-buttons">
                  <div>
                    <div className="gift-title">С Новым 2020 котогодом!</div>
                    Выберите праздничную котооткрытку и&nbsp;поздравьте друзей с&nbsp;наступающим 2020&nbsp;годом!
                  </div>
                  <div>
                    <ShareButton index={index} token={token} day={gifts.day} userId={userId} gift13Img={array[current]} scope={scope} />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="gift-slider">
            <Slider {...settings}>
              <div>
                <div className="slider-image">
                  <img src={mobile ? slide1m : slide1d} />
                </div>
              </div>
              <div>
                <div className="slider-image">
                  <img src={mobile ? slide2m : slide2d} />
                </div>
              </div>
              <div>
                <div className="slider-image">
                  <img src={mobile ? slide3m : slide3d} />
                </div>
              </div>
              <div>
                <div className="slider-image">
                  <img src={mobile ? slide4m : slide4d} />
                </div>
              </div>
              <div>
                <div className="slider-image">
                  <img src={mobile ? slide5m : slide5d} />
                </div>
              </div>
            </Slider>
          </div>
  
          {mobile && <ShareButton index={index} token={token} day={gifts.day} userId={userId} gift13Img={array[current]} scope={scope} />}
        </div>
      </div>

      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift13);
