import React from 'react';
import { connect } from 'react-redux';
import { popupStatus } from '../components/store/actions';

const RelesLnk = ({popupStatus}) => (
  <div className="rules-lnk" onClick={() => popupStatus(true)}>Правила акции</div>
)

export default connect(
  null,
  { popupStatus }
)(RelesLnk);
