import React from 'react';
import axios from 'axios';
import PageHead from '../PageHead';
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';

import { GROUP_ID, DOMAIN } from '../../constants';
import { playlistStatus } from '../../components/store/actions';
import connect from '@vkontakte/vk-connect';

import img from '../../img/gifts/playlist_img.jpg';

const Gift27 = ({index, goHome, goBot, token, mobile, gifts, userId, scope, playlist, playlistStatus, botStatus})  => {

  const clickBot = () => {
    if(userId){
      connect.send("VKWebAppAllowMessagesFromGroup", {"group_id": GROUP_ID, "key": userId});
    }
  }

  const getPlaylist = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramsObj = {
      vk_access_token_settings: searchParams.get('vk_access_token_settings'),
      vk_app_id: searchParams.get('vk_app_id'),
      vk_are_notifications_enabled: searchParams.get('vk_are_notifications_enabled'),
      vk_is_app_user: searchParams.get('vk_is_app_user'),
      vk_is_favorite: searchParams.get('vk_is_favorite'),
      vk_language: searchParams.get('vk_language'),
      vk_platform: searchParams.get('vk_platform'),
      vk_ref: searchParams.get('vk_ref'),
      vk_user_id: searchParams.get('vk_user_id'),
      sign: searchParams.get('sign'),
    }

    axios.get(`${DOMAIN}/api/send_pl.php`, {
      params: {
        day: gifts.day,
        ...paramsObj
      }
    })
    .then(function (response) {
      if(response.data.success){
        playlistStatus('1');
      }
      if(response.data.error){
        playlistStatus('-1');
      }
    })
    .catch(function (error) {
      console.log(error);
    }); 
  }

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
            <div className="gift-title">10 треков, для новогоднего настроения</div>
            Как Новый год встретишь, так и&nbsp;проведешь. Мы&nbsp;приготовили 10&nbsp;песен, которые подарят вам замурчательное настроение в&nbsp;этот праздник!
          </div>

          {mobile ? (
            <>
              <div className="playlist-box">
                <div>
                  <div className="playlist__in">
                    <div>
                      {botStatus == '-1' && (
                        <p>
                          Подпишитесь на&nbsp;чат-бота и&nbsp;получите новогодний плейлист прямо в&nbsp;личные сообщения!
                        </p>
                      )}

                      {botStatus != '-1' && (
                        <>
                          {playlist.status == '0' && (
                            <>
                              <p>Что может быть лучше, чем&nbsp;укутаться
                              в&nbsp;плед, взять любимца и&nbsp;включить новогоднюю музыку?</p>
                            </>
                          )}

                          {playlist.status == '1' && (
                            <p>Ваш плейлист уже прислал чат-бот,
                            он&nbsp;ждет тебя в&nbsp;личке.
                            <big>Наслаждайся!</big></p>
                          )}
                        </>
                      )}

                      <img src={img} />

                      {botStatus != '-1' && (
                        <>
                          {playlist.status == '0' && (
                            <p>Приготовили праздничный плейлист специально для&nbsp;вас!</p>
                          )}
                        </>
                      )}
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
              </div>

              {botStatus == '-1' ? (
                <>
                  <div className="share-button" onClick={clickBot}>Подписаться на чат-бота</div>
                </>
              ) : (
                <>
                  {playlist.status == '0' && <div className="share-button" onClick={getPlaylist}>Хочу плейлист</div>}
                  {playlist.status == '1' && <a href={`https://${mobile ? 'm.' : ''}vk.com/im?sel=-${GROUP_ID}`} target="_blank" className="share-button">Перейти в чат-бот</a>}
                </>
              )}
            </>
          ) : (
            <div className="playlist-box">
              <div>
                <div className="playlist__in">
                  <div>
                    <img src={img} />
                  </div>
                  <div>
                    {botStatus == '-1' && (
                      <p>
                        Подпишитесь на&nbsp;чат-бота и&nbsp;получите новогодний плейлист прямо в&nbsp;личные сообщения!
                      </p>
                    )}

                    {botStatus != '-1' && (
                      <>
                        {playlist.status == '0' && (
                          <>
                            <p>Что может быть лучше, чем&nbsp;укутаться
                            в&nbsp;плед, взять любимца и&nbsp;включить новогоднюю музыку?</p>
                            <p>Приготовили праздничный плейлист специально для&nbsp;вас!</p>
                          </>
                        )}

                        {playlist.status == '1' && (
                          <p>Ваш плейлист уже прислал чат-бот,
                          он&nbsp;ждет тебя в&nbsp;личке.
                          <big>Наслаждайся!</big></p>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {botStatus == '-1' ? (
                  <>
                    <div className="share-button" onClick={clickBot}>Подписаться на чат-бота</div>
                  </>
                ) : (
                  <>
                    {playlist.status == '0' && <div className="share-button" onClick={getPlaylist}>Хочу плейлист</div>}
                    {playlist.status == '1' && <a href={`https://${mobile ? 'm.' : ''}vk.com/im?sel=-${GROUP_ID}`} target="_blank" className="share-button">Перейти в чат-бот</a>}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
    playlist: state.playlist
  }),
  { playlistStatus }
)(Gift27);
