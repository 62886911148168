import React, { useState, useEffect } from 'react';
import PageHead from '../PageHead';
import ShareButton from '../ShareButton';

import camera from '../../img/camera.svg'

import mask1 from '../../img/gifts/mask1.jpg';
import mask2 from '../../img/gifts/mask2.jpg';

const Gift17 = ({index, goHome, goBot, token})  => {

  const [step, setStep] = useState(0);
  const [maskCount, setMaskCount] = useState(0);
  const [uploadedImg, setUploadedImg] = useState(null);

  useEffect(() => {
    const c = document.querySelector('.photo-frame__canvas');
    if(c){
      const context = c.getContext('2d');

      const sources = {
        image1: uploadedImg,
        image2: masks[maskCount]
      };
  
      loadImages(sources, function(images) {
        c.width = images.image1.width;
        c.height = images.image1.height;
        const coef = images.image1.width / images.image2.width
        context.drawImage(images.image1, 0, 0);
        context.drawImage(images.image2, 0, 0, images.image1.width, images.image2.height * coef);
      });
    }

  }, [maskCount, uploadedImg])

  const masks = [mask1, mask2];

  const loadImages = (sources, callback) => {
    var images = {};
    var loadedImages = 0;
    var numImages = 0;
    for(var src in sources) {
      numImages++;
    }
    for(var src in sources) {

      images[src] = new Image();
      images[src].onload = function() {
        if(++loadedImages >= numImages) {
          callback(images);
        }
      };
      images[src].src = sources[src];
    }
  }

  const changeFile = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(evt){

      setUploadedImg(evt.target.result);

      if(evt.target.readyState == FileReader.DONE) {
        const c = document.querySelector('.photo-frame__canvas');
        const context = c.getContext('2d');
  
        const sources = {
          image1: evt.target.result,
          image2: masks[0]
        };

        loadImages(sources, function(images) {
          c.width = images.image1.width;
          c.height = images.image1.height;
          const coef = images.image1.width / images.image2.width
          context.drawImage(images.image1, 0, 0);
          context.drawImage(images.image2, 0, 0, images.image1.width, images.image2.height * coef);
          setStep(step + 1);
        });
      }
    }
  }
  
  const clear = () => {
    document.getElementById('photo-frame__input').value = '';
    setStep(step - 1);
    setMaskCount(0);
  }

  const sendMod = () => {
    const c = document.querySelector('.photo-frame__canvas');
    console.log(c.toDataURL())
    setStep(step + 1);
  }

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
        <div className="page page_gift">
          <div className={`gift gift${index}`}>

          {step === 0 && (
            <div className="gift-f">
              <div>
                <div className="gift__head">
                  <div className="gift__head-num">{index}</div>
                  <div className="gift-title">Новогодние которамки</div>
                  Загрузите фото своего любимца, подберите подходящее поздравление и поделитесь открыткой! 
                </div>
              </div>
              <div>
                <div className="share-button" onClick={() => setStep(step + 1)}>Начать</div>
              </div>
            </div>
          )}

          { (step === 1 || step === 2 ) && (
            <>
              <div className="gift__head">
                <div className="gift__head-num">{index}</div>
                <div className="gift-title">Новогодние которамки</div>
              </div>
              <div className={step === 2 ? 'photo-frame disable' : 'photo-frame'}>
                <input id="photo-frame__input" accept="image/*" type="file" onChange={e => changeFile(e)} />
                <div>
                  <div className="photo-frame__icon">
                    <img src={camera} />
                    Загруите фотографию <br />с вашим котиком
                  </div>
                </div>
              </div>

              <div className={step === 2 ? 'photo-frame-result' : 'photo-frame-result disable'}>
                <div className="photo-frame-result__close" onClick={clear} />

                {maskCount > 0 && <div className="photo-frame-result__left" onClick={() => setMaskCount(maskCount - 1)} />}
                {maskCount < masks.length - 1 && <div className="photo-frame-result__right" onClick={() => setMaskCount(maskCount + 1)} />}
                <canvas className="photo-frame__canvas" />
              </div>

              <div className="share-button" onClick={sendMod}>Отправить на модерацию</div>
            </>
          )}

          {step === 3 && (
            <div className="gift-f">
              <div>
                <div className="gift__head">
                  <div className="gift__head-num">{index}</div>
                  <div className="gift-title">Новогодние которамки</div>
                  Ваша открытка с которамкой совсем скоро будет промодерирована! Следите за статусом в приложении и подпишитесь на бота, чтобы он выслал статус в личные сообщения
                </div>
              </div>
              <div>
                <div className="share-button">Подписаться на чат-бота</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Gift17;
