import React from 'react';
import connect from '@vkontakte/vk-connect';
import { GROUP_ID } from '../constants';

const BotSubscribe = ({userId, children}) => {

  const click = () => {
    if(userId){
      connect.send("VKWebAppAllowMessagesFromGroup", {"group_id": GROUP_ID, "key": userId});
    }
  }

  return (
    <>
<div className="share-button big" onClick={click}>{children ? children : 'Подписаться'}</div>
    </>
  );
}

export default BotSubscribe;
