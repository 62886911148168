import React, { useEffect } from 'react';
import PageHead from '../PageHead';
import { connect as reduxConnect } from 'react-redux';

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';
import ShareButton from '../ShareButton';

import img1 from '../../img/gifts/gift4_img1.svg';
import img2 from '../../img/gifts/gift4_img2.svg';
import img3 from '../../img/gifts/gift4_img3.svg';

const Gift11 = ({index, goHome, goBot, token, mobile, gifts, userId, scope})  => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  return (
    <div className="wrap" dada-scope={scope}>
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
        <div className="page page_gift">
          <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
            {mobile ? (
              <>
                <div className="gift-title">Мемы с котами</div>
                Смешные новогодние мемы от Whiskas! Делись с друзьями понравившимся или всем списком
              </>
            ) : (
              <>
                <div className="gift__head-with-buttons">
                  <div>
                    <div className="gift-title">Мемы с котами</div>
                    Смешные новогодние мемы от Whiskas! Делись с друзьями понравившимся или всем списком
                  </div>
                  <div>
                    <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                  </div>
                </div>
              </>
            )}
  
          </div>
  
          {!mobile ? (
            <div className="gift11-desctope">
              <div className="gift__dsc">
                Какой-то смешной <br />мемо-кото-текст
                <div><img src={img1} className="gift__img" /></div>
              </div>
              <div className="gift__dsc">
                Какой-то смешной <br />мемо-кото-текст
                <div><img src={img2} className="gift__img" /></div>
              </div>
              <div className="gift__dsc">
                Какой-то смешной <br />мемо-кото-текст
                <div><img src={img3} className="gift__img" /></div>
              </div>
            </div>
          ) : (
            <>
              <div className="gift__dsc">
                Какой-то смешной <br />мемо-кото-текст
                <div><img src={img1} className="gift__img gift4__img1" /></div>
              </div>
              <div className="gift__dsc">
                <div className="gift__dsc_in">
                  <div>Какой-то смешной мемо-кото-текст</div>
                  <div><img src={img2} className="gift__img" /></div>
                </div>
              </div>
              <div className="gift__dsc">
                <div className="gift__dsc_in">
                  <div><img src={img3} className="gift__img" /></div>
                  <div>Какой-то смешной мемо-кото-текст</div>
                </div>
              </div>
            </>
          )}
          {mobile && <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />}
        </div>
      </div>
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift11);
