import { SET_PLAYLIST_STATUS } from './constants';

const initialState = {
  status: null
};

export default function playlist(state = initialState, action) {
  if(action.type === SET_PLAYLIST_STATUS){
    return {
      ...state,
      status: action.payload,
    };
  }
	return state;
}