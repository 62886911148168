import React, { useEffect } from 'react';
import PageHead from '../PageHead';
import ShareButton from '../ShareButton';
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';
import ButtonToBottom from '../../components/ButtonToBottom';

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

import img1 from '../../img/gifts/tu-du-1_2.jpg';
import img2 from '../../img/gifts/tu-du-2_2.jpg';
import img3 from '../../img/gifts/tu-du-3_2.jpg';
import img4 from '../../img/gifts/tu-du-4_2.jpg';
import img5 from '../../img/gifts/tu-du-5_2.jpg';

const Gift23 = ({index, goHome, goBot, token, mobile, gifts, userId, scope}) => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
  
            {mobile ? (
              <>
                <div className="gift-title">Рецепт «Как порадовать кота»</div>
                Радовать любимцев всегда здорово! Возьмите на&nbsp;заметку 5&nbsp;замурчательных приятностей, которые не&nbsp;оставят ни&nbsp;одного котейку равнодушным.
                {scope != '' && <ButtonToBottom />}
              </>
            ) : (
              <div className="gift__head-with-buttons">
                <div>
                  <div className="gift-title">Рецепт «Как порадовать кота»</div>
                  Радовать любимцев всегда здорово! Возьмите на&nbsp;заметку 5&nbsp;замурчательных приятностей, которые не&nbsp;оставят ни&nbsp;одного котейку равнодушным.
                </div>
                <div>
                  <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                </div>
              </div>
            )}
          </div>
          {mobile ? (
            <>
              <div className="gift__outer-title">1. Поиграйте с котиком хотя бы&nbsp;15-20&nbsp;минут</div>
              <div className="gift__dsc">
                <img src={img1} className="gift__img" />
              </div>
              <div className="gift__outer-title">2. Принесите ему новую игрушку. Наблюдайте, как он&nbsp;играет с&nbsp;ее&nbsp;коробкой</div>
              <div className="gift__dsc">
                <img src={img2} className="gift__img" />
              </div>
              <div className="gift__outer-title">3. Купите котейке красивую мисочку</div>
              <div className="gift__dsc">
                <img src={img3} className="gift__img" />
              </div>
              <div className="gift__outer-title">4. Побалуйте котейку новогодним WHISKAS® «Праздничный микс» c&nbsp;особенным вкусом</div>
              <div className="gift__dsc">
                <img src={img4} className="gift__img" />
              </div>
              <div className="gift__outer-title">5. Почешите за&nbsp;ушком или погладьте пушистый животик (только берегите руки)</div>
              <div className="gift__dsc">
                <img src={img5} className="gift__img" />
              </div>
              <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
            </>
          ) : (
            <>
              <div className="dgift3">
                <div>
                  <div className="gift__outer-title">1. Поиграйте с котиком <br />хотя бы&nbsp;15-20&nbsp;минут</div>
                  <div className="gift__dsc">
                    <img src={img1} className="gift__img" />
                  </div>
                </div>
                <div>
                  <div className="gift__outer-title">2. Принесите ему новую игрушку. Наблюдайте, как он&nbsp;играет с&nbsp;ее&nbsp;коробкой</div>
                  <div className="gift__dsc">
                    <img src={img2} className="gift__img" />
                  </div>
                </div>
              </div>
  
              <div className="dgift3">
                <div>
                  <div className="gift__outer-title">3. Купите котейке <br />красивую мисочку</div>
                  <div className="gift__dsc">
                    <img src={img3} className="gift__img" />
                  </div>
                </div>
                <div>
                  <div className="gift__outer-title">4. Побалуйте котейку новогодним WHISKAS® «Праздничный микс» c&nbsp;особенным вкусом</div>
                  <div className="gift__dsc">
                    <img src={img4} className="gift__img" />
                  </div>
                </div>
              </div>
  
              <div className="dgift3">
                <div>
                  <div className="gift__outer-title">5. Почешите за&nbsp;ушком или погладьте пушистый животик (только берегите руки)</div>
                  <div className="gift__dsc">
                    <img src={img5} className="gift__img" />
                  </div>
                </div>
                <div />
              </div>
            </>
          )}
        </div>
      </div>
  
      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift23);
