import React, { useEffect } from 'react';
import ShareButton from '../ShareButton';
import PageHead from '../PageHead';
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';

import img from '../../img/gifts/gift21n.jpg'
import img_mob from '../../img/gifts/gift21nn.jpg'

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

const Gift21 = ({index, goHome, goBot, token, mobile, gifts, userId, scope}) => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  const text = 'Мрр… стройте планы и&nbsp;мечты — 2020 год будет положительным для&nbsp;любых начинаний. Будьте любопытными, развивайтесь и&nbsp;усердно работайте, чтобы у&nbsp;вас с&nbsp;котиком была отличная жизнь!';

  if(mobile){
    return (
      <div className="wrap">
        <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
        <div className="page page_gift">
          <div className={`gift gift${index}`}>
            <div className="gift__head">
              <div className="gift__head-num">{gifts.day}</div>
              <div className="gift-title">Котик-предсказатель</div>
              Узнайте, что&nbsp;замурчательного ждет вас в&nbsp;2020&nbsp;году!
            </div>
            <img src={img_mob} className="gift1imgmob" />
            <div className="gift__dsc gift__dsc_center gift__dsc_norad">
            <div dangerouslySetInnerHTML={{ __html: text}} />
            </div>
            <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
          </div>
        </div>

        <RelesLnk />
      </div>
    );
  }

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
            <div className="gift-title">Котик-предсказатель</div>
              Узнайте, что&nbsp;замурчательного ждет вас в&nbsp;2020&nbsp;году!
          </div>
          <div className="gift__dsc">
            <div className="dgift__dsc dgift__dsc-center">
              <div>
                <img src={img} width="291" height="319" />
              </div>
              <div>
                <div className="dgift1-text asd" dangerouslySetInnerHTML={{ __html: text}} />
                <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift21);
