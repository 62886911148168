import { SET_FRAME_STATUS } from './constants';

const initialState = {
  data: null
};

export default function frame(state = initialState, action) {
  if(action.type === SET_FRAME_STATUS){
    return {
      ...state,
      data: action.payload,
    };
  }
	return state;
}