import React, { useState, useEffect } from 'react';
import PageHead from '../PageHead';
import img from '../../img/cat_l.svg';
import ShareButton from '../ShareButton';
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

import qr from '../../img/qr.svg';

const Gift7 = ({index, goHome, goBot, token, mobile, gifts, userId, scope}) => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  const [count, setCount] = useState(0);
  const [active, setActive] = useState(false);

  const rand = Math.round(Math.random() * 4);

  const down = () => {
    if(!active){
      setActive(true);
    }
  }

  const up = () => {
    if(active){
      setTimeout(() => {
        setCount(count + 1);
        setActive(false);
      }, 2000);
    }
  }

  const getResult = () => {

    const array = [
      `В новом году хозяин будет|чаще радовать вас самым|аппетитным праздничным меню!`,
      `Говорят, разбитая посуда|приносит счастье.|Но не советуем вам|проверять это в 2020 году.`,
      `Не кусайте руку, которая|вас кормит. Ну, разве что,|если эта рука хочет|почесать вам пузико.`,
      `В новом году проводите каждый|день с пользой: сладко спите,|вкусно ешьте, громко мурчите|и мяукайте по ночам.`,
      `Начните внимательнее следить|за своим хозяином. Кажется,|он смотрит на других котиков|в интернете.`,
    ]

    return array[rand];
  }

  if(!mobile){
    return (
      <div className="wrap">
        <PageHead goHome={goHome} goBot={goBot} goBack={ (count === 0 || count === 2 ) ? goHome : () => setCount(count - 1)}/>
        <div className="page page_gift">
          <div className={`gift gift${index}`}>
            <div className="gift__head">
              <div className="gift__head-num">{gifts.day}</div>
              <div className="gift-title">Предсказание для кота<br />по&nbsp;скану его лапки</div>
            </div>
            <div className="gift__dsc gift__dsc7">
              <div className="gift7-qr">
                <div>
                  <img src={qr} />
                </div>
                <div>
                  Хотите узнать, что замурчательного ждет котейку в новом году.<br />
                  Тогда скорее берите свой смартфон, сканируйте QR-код<br />
                  и получите праздничное предсказание!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={(count === 0 || count === 2 ) ? goHome : () => setCount(count - 1)}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift-f">
            <div>
              <div className="gift__head">
                <div className="gift__head-num">{gifts.day}</div>
                <div className="gift-title">Предсказание для кота по&nbsp;скану его лапки</div>
                {count === 0 && (
                  <>
                  <p>Хотите узнать, что замурчательного ждет котейку в&nbsp;новом году? Тогда приложите его пушистую лапку к&nbsp;экрану и&nbsp;получите праздничное предсказание!</p>
                  <div className="gift__head-in">
                    <div>1. Возьмите кота</div>
                    <div>2. Приложите лапку к экрану</div>
                    <div>3. Получите предсказание </div>
                  </div>
                  </>
                )}
                {count === 1 && (<p>Хмм… А&nbsp;это точно лапка?<br />Приложите-ка ее еще раз!</p>)}
                {count === 2 && (<p dangerouslySetInnerHTML={{ __html: getResult().replace(/\|/g, ' ') }} />)}
              </div>
            </div>
            <div>
              {count === 2 ? (
                <>
                  <ShareButton index={index} token={token} g7id={getResult()} day={gifts.day} userId={userId} scope={scope} />
                </>
              ) : (
                <div className={active ? 'cat-scan active' : 'cat-scan'} onTouchEnd={up} onTouchStart={down}>
                  <div className="cat-scan-c" data-id="1" />
                  <div className="cat-scan-c" data-id="2" />
                  <div className="cat-scan-c" data-id="3" />
                  <div className="cat-scan__in">
                    <img src={img} />
                    Место,<br />
                    куда прикладывать<br />
                    лапку
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <RelesLnk />
    </div>
  );
  
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift7);

