import React, { useEffect } from 'react';
import PageHead from '../PageHead';
import { connect as reduxConnect } from 'react-redux';
import ShareButton from '../ShareButton';
import RelesLnk from '../../components/RelesLnk';
import ButtonToBottom from '../../components/ButtonToBottom';

import img from '../../img/gifts/desc/g5nd.jpg'

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

const gift5Text = () => (
  <>
    <p>Рады подарить вам счастье для кота, ведь счастливый кот&nbsp;—&nbsp;счастливый хозяин. </p>
    <p>Рассказываем о&nbsp;5&nbsp;пунктах, чтобы понять, как котик себя чувствует:</p>
    <p><strong>Голос</strong></p>
    <p>Если кот мяукает на высоких тонах&nbsp;—&nbsp;все хорошо, если на низких&nbsp;—&nbsp;он недоволен. </p>
    <p><strong>Здоровье</strong></p>
    <p>Когда все в порядке, котик вылизывает себя, а&nbsp;заодно и&nbsp;вас.</p>
    <p><strong>Поза</strong></p>
    <p>У счастливого кота хвост торчком: так он приветствует своих друзей. У&nbsp;тревожного кота прямые лапы и&nbsp;шерсть дыбом.</p>
    <p><strong>Поведение</strong></p>
    <p>Довольный кот игрив и&nbsp;с&nbsp;удовольствием делает тыгыдык-тыгыдык по&nbsp;дому.</p>
    <p><strong>Аппетит</strong></p>
    <p>Признак хорошего самочувствия не&nbsp;только у&nbsp;человека, но&nbsp;и&nbsp;у&nbsp;котейки. Если он не ест&nbsp;—&nbsp;бегом к&nbsp;ветеринару.</p>
  </>
)

const Gift5 = ({index, goHome, goBot, token, mobile, gifts, userId, scope}) => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
  
            {mobile ? (
              <>
                <div className="gift-title">Полезные советы</div>
                Как узнать, что кошка счастлива?
                {scope != '' && <ButtonToBottom />}
              </>
            ) : (
              <>
                <div className="gift__head-with-buttons">
                  <div>
                    <div className="gift-title">Полезные советы</div>
                    Как узнать, что кошка счастлива?
                  </div>
                  <div>
                    <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                  </div>
                </div>
              </>
            )}
  
  
  
          </div>
          {mobile ? (
            <>
              <div className="gift5-box"></div>
              <div className="gift__dsc gift__dsc_center gift__dsc_norad gift__dsc_gift5">
              {gift5Text()}
              </div>
              <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
            </>
          ) : (
            <>
              <div className="gift__dsc">
                <div className="dgift__dsc">
                  <div>
                    <img src={img} width="291" height="319" />
                  </div>
                  <div>
                  {gift5Text()}
                  </div>
                </div>
              </div>
            </>
          )}
  
        </div>
      </div>
  
      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift5);
