import React, { useState, useEffect } from 'react';
import PageHead from '../PageHead';
import ShareButton from '../ShareButton';
import { connect as reduxConnect } from 'react-redux';
import axios from 'axios';

import camera from '../../img/camera.svg'

import moderate_ico from '../../img/checked.svg';

import connect from '@vkontakte/vk-connect';
import { DOMAIN, GROUP_ID, APP_ID } from '../../constants';
import { postcardStatus, popupPostcardStatus } from '../../components/store/actions';

import mask1 from '../../img/gifts/postcard_mask1.png';
import mask2 from '../../img/gifts/postcard_mask2.png';
import mask3 from '../../img/gifts/postcard_mask3.png';
import mask4 from '../../img/gifts/postcard_mask4.png';
import mask5 from '../../img/gifts/postcard_mask5.png';

import loadImage from 'blueimp-load-image';

import RelesLnk from '../../components/RelesLnk';

const Gift37 = ({index, goHome, goBot, token, mobile, gifts, userId, scope, botStatus, postcard, postcardStatus, popupPostcardStatus, popuppostcard})  => {

  const clickBot = () => {
    if(userId){
      connect.send("VKWebAppAllowMessagesFromGroup", {"group_id": GROUP_ID, "key": userId});
    }
  }

  const [step, setStep] = useState(0);
  const [uploadedImg, setUploadedImg] = useState(null);
  const [drag, setDrag] = useState(false);
  const [coords, setCoords] = useState({x: 0, y: 0});
  const [startCoords, setStartCoords] = useState({sx: 0, sy: 0});
  const [endCoords, setEndCoords] = useState({ex: 0, ey: 0});
  const [textIndex, setTextIndex] = useState(0);

  const textArray = [
    mask1,
    mask2,
    mask3,
    mask4,
    mask5
  ]

  useEffect(() => {
    const c = document.querySelector('.postcard__canvas');
    if(c){
      const context = c.getContext('2d');

      const sources = {
        image1: uploadedImg || textArray[textIndex],
        image2: textArray[textIndex]
      };
  
      loadImages(sources, function(images) {
        context.clearRect(0, 0, c.width, c.height);
        let {x, y} = coords;
        const detectY = 409 - images.image1.height;
        const detectX = 500 - images.image1.width;

        if(x > 150){
          x = 150;
        }

        if(y > 60){
          y = 60;
        }

        if(y < detectY){
          y = detectY;
        }

        if(x < detectX){
          x = detectX;
        }

        context.drawImage(images.image1, x, y);
        context.drawImage(images.image2, 0, 0);
      });
    }

    if(postcard.data.status == 1){
      if(scope == ''){
        connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
      }
    }
  })

  const loadImages = (sources, callback) => {
    var images = {};
    var loadedImages = 0;
    var numImages = 0;
    for(var src in sources) {
      numImages++;
    }
    for(var src in sources) {

      images[src] = new Image();
      images[src].onload = function() {
        if(++loadedImages >= numImages) {
          callback(images);
        }
      };
      images[src].src = sources[src];
    }
  }

  const changeFile = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(evt){
      if(evt.target.readyState == FileReader.DONE) {
        loadImage(
          evt.target.result,
          (img) => {
            setUploadedImg(img.toDataURL());
          },
          {
            maxWidth: 650,
            orientation: true,
            canvas: true
          } // Options
        );
      }
    }
  }
  
  const clear = () => {
    document.getElementById('postcard-upload-button__input').value = '';
    setUploadedImg(null);
  }

  const sendMod = () => {
    const c = document.querySelector('.postcard__canvas');

    const searchParams = new URLSearchParams(window.location.search);
    const paramsObj = {
      vk_access_token_settings: searchParams.get('vk_access_token_settings'),
      vk_app_id: searchParams.get('vk_app_id'),
      vk_are_notifications_enabled: searchParams.get('vk_are_notifications_enabled'),
      vk_is_app_user: searchParams.get('vk_is_app_user'),
      vk_is_favorite: searchParams.get('vk_is_favorite'),
      vk_language: searchParams.get('vk_language'),
      vk_platform: searchParams.get('vk_platform'),
      vk_ref: searchParams.get('vk_ref'),
      vk_user_id: searchParams.get('vk_user_id'),
      sign: searchParams.get('sign'),
    }

    axios({
      method: 'post',
      url: `${DOMAIN}/api/postcard.php`,
      data: {
        day: gifts.day,
        file: c.toDataURL('image/jpeg', 1.0),
        ...paramsObj
      }
    })
    .then(function (response) {
      postcardStatus({
        status: '0',
      });
    })
    .catch(function (error) {
      console.log(error);
    }); 


    setStep(step + 1);
  }

  const getCoords = e => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    return {x, y};
  }

  const mousemove = e => {
    if(drag){
      const {x, y} = getCoords(e);
      const {sx, sy} = startCoords;
      const rx = x - sx;
      const ry = y - sy;
      setCoords({x: rx, y: ry});
    }
  }

  const mouseup = e => {
    const {x, y} = coords;
    setEndCoords({ex: x, ey: y});
    setDrag(false);
  }

  const mousedown = e => {
    setDrag(true);
    const {x, y} = getCoords(e);
    const {ex, ey} = endCoords;
    const rx = x - ex;
    const ry = y - ey;
    setStartCoords({sx: rx, sy: ry});
  }

  const getCoordsTouch = e => {
    const rect = e.target.getBoundingClientRect();
    const x = e.touches[0].clientX - rect.left;
    const y = e.touches[0].clientY - rect.top;
    return {x, y};
  }

  const touchstart = e => {
    setDrag(true);
    const {x, y} = getCoordsTouch(e);
    const {ex, ey} = endCoords;
    const rx = x - ex;
    const ry = y - ey;
    setStartCoords({sx: rx, sy: ry});
  }

  const touchmove = e => {
    if(drag){
      const {x, y} = getCoordsTouch(e);
      const {sx, sy} = startCoords;
      const rx = x - sx;
      const ry = y - sy;
      setCoords({x: rx, y: ry});
    }
  }

  const touchend = e => {
    const {x, y} = coords;
    setEndCoords({ex: x, ey: y});
    setDrag(false);
  }

  const next = () => {
    if(textIndex >= textArray.length - 1){
      setTextIndex(0);
    }
    else{
      setTextIndex(textIndex + 1);
    }
  }

  const prev = () => {
    if(textIndex <= 0){
      setTextIndex(textArray.length - 1);
    }
    else{
      setTextIndex(textIndex - 1);
    }
  }

  return (
    <>
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
        <div className="page page_gift">
          <div className={`gift gift${index}`}>

          { (postcard.data.status == '-1' || postcard.data.status == '2' ) && (
            <>
              {step === 0 && (
                <>
                  <div className="gift__head">
                    <div className="gift__head-num">{gifts.day}</div>
                    <div className="gift-title">Новогодние котооткрытки</div>
                    {postcard.data.status == '2' ? (
                      <>
                        К сожалению, открытка не прошла модерацию.<br />
                        Попробуйте загрузить другое фото
                      </>
                    ) : (
                      <>
                        Загрузите фото своего любимца, подберите<br />
                        подходящее поздравление и поделитесь открыткой!
                      </>
                    )}
                  </div>

                  <div className="postcard-wrapper">
                    <div className="postcard-wrapper__logo" />
                    <div className="postcard-wrapper__left" onClick={prev} />
                    <div className="postcard-wrapper__right" onClick={next} />

                    <div>
                      {mobile ? (
                        <>
                          <div className="postcard__canvas-wrapper">
                            {uploadedImg && <div className="postcard-wrapper__clear" onClick={clear} />}
                            <div className={uploadedImg ? 'postcard-upload-button disable' : 'postcard-upload-button'}>
                              <input id="postcard-upload-button__input" type="file" onChange={e => changeFile(e)} />
                              <div>
                                <div className="postcard-upload-button__in">
                                  <img src={camera} />
                                  Загрузите<br />фотографию <br />котика
                                </div>
                              </div>
                            </div>
                            <canvas className="postcard__canvas" width="650" height="600" onTouchMove={touchmove} onTouchEnd={touchend} onTouchStart={touchstart} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="postcard__canvas-wrapper" onMouseMove={mousemove} onMouseUp={mouseup} onMouseDown={mousedown}>
                            {uploadedImg && <div className="postcard-wrapper__clear" onClick={clear} />}
                            <div className={uploadedImg ? 'postcard-upload-button disable' : 'postcard-upload-button'}>
                              <input id="postcard-upload-button__input" type="file" onChange={e => changeFile(e)} />
                              <div>
                                <div className="postcard-upload-button__in">
                                  <img src={camera} />
                                  Загрузите<br />фотографию <br />котика
                                </div>
                              </div>
                            </div>
                            <canvas className="postcard__canvas" width="650" height="600" />
                          </div>
                          {uploadedImg && <div className="share-button" onClick={sendMod}>Отправить на модерацию</div>}
                        </>
                      )}
                    </div>
                  </div>

                  { (mobile && uploadedImg) && <div className="share-button" onClick={sendMod}>Отправить на модерацию</div>}
                </>
              )}
              {step === 1 && (
                <div className="gift-f">
                  <div>
                    <div className="gift__head">
                      <div className="gift__head-num">{gifts.day}</div>
                      <div className="gift-title">Новогодние котооткрытки</div>
                    Загрузите фото своего любимца, подберите<br />
                    подходящее поздравление и поделитесь открыткой!
                    </div>
                  </div>
                  <div className="postcard__moderate-page">
                    <div>
                      <img src={moderate_ico} />
                      Ваша открытка совсем скоро будет промодерирована! <br />
                      Следите за статусом в приложении и подпишитесь на бота, <br />
                      чтобы он выслал статус в личные сообщения 
                      {botStatus == '-1' && <div className="share-button" onClick={clickBot}>Подписаться на чат-бота</div>}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {postcard.data.status == '0' && (
            <>
              <div className="gift-f">
                <div>
                  <div className="gift__head">
                    <div className="gift__head-num">{gifts.day}</div>
                    <div className="gift-title">Новогодние котооткрытки</div>
                  Загрузите фото своего любимца, подберите<br />
                  подходящее поздравление и поделитесь открыткой!
                  </div>
                </div>
                <div className="postcard__moderate-page">
                  <div>
                    <img src={moderate_ico} />
                    Ваша открытка совсем скоро будет промодерирована! <br />
                    Следите за статусом в приложении и подпишитесь на бота, <br />
                    чтобы он выслал статус в личные сообщения 
                    {botStatus == '-1' && <div className="share-button" onClick={clickBot}>Подписаться на чат-бота</div>}
                  </div>
                </div>
              </div>
            </>
          )}

          {postcard.data.status == '1' && (
            <>
              <div className="gift-f">
                <div>
                  <div className="gift__head">
                    <div className="gift__head-num">{gifts.day}</div>
                    {mobile ? (
                      <>
                        <div className="gift-title">Новогодние котооткрытки</div>
                        Модерация прошла успешно!<br />
                        Поделитесь открыткой с друзьями
                      </>
                    ) : (
                      <>
                        <div className="gift__head-with-buttons">
                          <div>
                            <div className="gift-title">Новогодние котооткрытки</div>
                            Модерация прошла успешно!<br />
                            Поделитесь открыткой с друзьями
                          </div>
                          <div>
                            <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} gift37={DOMAIN + postcard.data.img} />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="postcard-wrapper">
                  <div className="postcard-wrapper__logo" />
                  {postcard.data.img && <img src={DOMAIN + postcard.data.img} className="postcard-result-image" />}
                </div>
              </div>

              {mobile && <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} gift37={DOMAIN + postcard.data.img} />}
            </>
          )}
        </div>
      </div>
      {mobile && <RelesLnk />}
    </div>

    <div className="rules-lnk rules-lnk-photo" onClick={() => popupPostcardStatus(true)}>Правила загрузки фотографий</div>
    </>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
    postcard: state.postcard,
    popuppostcard: state.popuppostcard
  }),
  { postcardStatus, popupPostcardStatus }
)(Gift37);
