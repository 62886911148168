import React from 'react';
import BotSubscribe from './BotSubscribe';
import { GROUP_ID } from '../constants';

const Bot = ({userId, botStatus, mobile}) => (
  <div className="gift bot">
    <div className="gift-f">
      <div>
        <div className="gift__head">
          <div className="bot__icon" />
          <div className="gift-title">Кот-бот!</div>
          {botStatus == '-1' ? (
            <>
              Мрр… подпишитесь на кота-бота! Он будет держать вас в курсе важных обновлений и напоминать вовремя заходить в приложение, чтобы не пропустить подарки.
            </>
          ) : (
            <>
              Вы подписаны на кота-бота
            </>
          )}
        </div>
      </div>
      <div>
        {botStatus == '-1' ? (
          <BotSubscribe userId={userId} />
        ) : (
          <a href={`https://${mobile ? 'm.' : ''}vk.com/im?sel=-${GROUP_ID}`} target="_blank" className="share-button">Перейти в чат-бот</a>
        )}
      </div>
    </div>
  </div>
);

export default Bot;
