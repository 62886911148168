import React, { useState, useEffect } from 'react';
import PageHead from '../PageHead';
import ShareButton from '../ShareButton';
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';

import img from '../../img/gifts/find.jpg';
import ico from '../../img/gifts/g15_ico.svg';

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

const Gift15 = ({index, goHome, goBot, token, mobile, gifts, userId, scope})  => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  const [step, setStep] = useState(0);

  const next = () => {
    setStep(1);
    window.scrollTo(0, 0);
  }

  if(mobile){
    return (
      <div className="wrap">
        <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
          <div className="page page_gift">
            <div className={`gift gift${index}`}>
            <div className="gift-f">
              <div>
                <div className="gift__head">
                  <div className="gift__head-num">{gifts.day}</div>
                  <div className="gift-title">Найдите кота на&nbsp;картинке</div>
                  {step === 0 ? (
                    <>
                      А&nbsp;вы настоящий котолюбитель? Тогда найдите котейку на&nbsp;этой картинке и&nbsp;нажмите на&nbsp;него!
                    </>
                  ) : (
                    <>
                      Да у&nbsp;вас просто кошачье чутье! Поделитесь картинкой с&nbsp;друзьями и&nbsp;узнайте, насколько они внимательные
                    </>
                  )} 
                </div>
              </div>
              <div>
                {step === 0 && (
                  <div className="gift__search">
                    <img src={img} useMap="#searchmap" />
                    <svg viewBox="0 0 750 750">
                      <path d="M101,377,147,394,175,382,206,393,254,380,253,429,261,472,239,495,253,548,172,530,100,532,118,491,95,415" onClick={next} />
                    </svg>
                  </div>
                )}
  
                {step === 1 && <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />}
              </div>
            </div>
  
  
          </div>
        </div>

        <RelesLnk />
      </div>
    );
  }

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
        <div className="page page_gift">
          <div className={`gift gift${index} desctope`}>
            <div className="gift__head">
              <div className="gift__head-num">{gifts.day}</div>

              <div className="gift__head-with-buttons">
                <div>
                  <div className="gift-title">Найдите кота на&nbsp;картинке</div>
                  {step === 0 && (
                    <>
                      А&nbsp;вы настоящий котолюбитель? Тогда найдите котейку на&nbsp;этой картинке и&nbsp;нажмите на&nbsp;него!
                    </>
                  )}
                </div>
                <div>
                  {step === 1 && <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />}
                </div>
              </div>
            </div>

            {step === 0 && (
              <div className="gift__dsc">
                <div className="gift__search">
                  <img src={img} useMap="#searchmap" />
                  <svg viewBox="0 0 750 750">
                    <path d="M101,377,147,394,175,382,206,393,254,380,253,429,261,472,239,495,253,548,172,530,100,532,118,491,95,415" onClick={next} />
                  </svg>
                </div>
              </div>
            )}

            {step === 1 && (
              <div className="gift__dsc gift15__result">
                <img src={ico} className="ico" />
                <p>Да у&nbsp;вас просто кошачье чутье! Поделитесь картинкой с&nbsp;друзьями и&nbsp;узнайте, насколько они внимательные</p>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift15);
