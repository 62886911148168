import React, { useState, useEffect } from 'react';
import PageHead from '../PageHead';
import { connect as reduxConnect } from 'react-redux';
import ShareButton from '../ShareButton';

import catbox1 from './../../img/gifts/img1_1.jpg';
import catbox1r from './../../img/gifts/img1_2.jpg';
import catbox2 from './../../img/gifts/img2_1.jpg';
import catbox2r from './../../img/gifts/img2_2.jpg';
import catbox3 from './../../img/gifts/img3_1.jpg';
import catbox3r from './../../img/gifts/img3_2.jpg';


import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

const Gift12 = ({index, goHome, goBot, token, mobile, gifts, userId, scope}) => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);


  const stepSize = 6;
  const [step, setStep] = useState(1);

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
            {step === stepSize ? (
              <>
                {mobile ? (
                  <>
                    <div className="gift-title">Любопытные коты помещаются в&nbsp;любые коробки</div>
                    Будьте такими же в&nbsp;2020&nbsp;году и&nbsp;ничто вас не&nbsp;остановит!
                  </>
                ) : (
                  <>
                    <div className="gift__head-with-buttons">
                      <div>
                        <div className="gift-title"> Любопытные коты помещаются в&nbsp;любые коробки</div>
                        Будьте такими же в&nbsp;2020&nbsp;году и&nbsp;ничто вас не&nbsp;остановит!
                      </div>
                      {step === stepSize && (
                        <div>
                          <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                        </div>
                      )}
                    </div>
                  </>
                )}

              </> 
            ) : (
              <>
                <div className="gift-title">Угадайте, поместится ли кот в коробку? </div>
                Любопытные котики просто не&nbsp;могут пройти мимо коробок! Мы покажем 3&nbsp;разных коробочки, а&nbsp;вы&nbsp;определите, поместится в&nbsp;них котейка или&nbsp;нет.
              </>
            )}
          </div>

          {step === stepSize && (
            <div className={!mobile ? 'gift12-desctope gift12-desctope-final' : null}>
              <div className="gift__dsc gift__dsc_catbox">
                <img src={catbox3r} />
              </div>
            </div>
          )}

          {step === 1 && (
            <div className={!mobile ? 'gift12-desctope' : null}>
              <div className="gift__dsc gift__dsc_catbox">
                <img src={catbox1} />
              </div>

              <div className="buttons-double">
                <div>
                  <div className="share-button" onClick={() => setStep(step + 1)}>Поместится</div>
                </div>
                <div>
                  <div className="share-button" onClick={() => setStep(step + 1)}>Не поместится</div>
                </div>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className={!mobile ? 'gift12-desctope' : null}>
              <div className="gift__dsc gift__dsc_catbox gift__dsc_catbox-result">
                <img src={catbox1r} />
                Такая коробка - вообще для кота не&nbsp;проблема! А&nbsp;остальные?
              </div>

              <div className="share-button" onClick={() => setStep(step + 1)}>Следующая коробка</div>
            </div>
          )}

          {step === 3 && (
            <div className={!mobile ? 'gift12-desctope' : null}>
              <div className="gift__dsc gift__dsc_catbox">
                <img src={catbox2} />
              </div>

              <div className="buttons-double">
                <div>
                  <div className="share-button" onClick={() => setStep(step + 1)}>Поместится</div>
                </div>
                <div>
                  <div className="share-button" onClick={() => setStep(step + 1)}>Не поместится</div>
                </div>
              </div>
            </div>
          )}

          {step === 4 && (
            <div className={!mobile ? 'gift12-desctope' : null}>
              <div className="gift__dsc gift__dsc_catbox gift__dsc_catbox-result">
                <img src={catbox2r} />
                Такая коробка - вообще для кота не&nbsp;проблема! А&nbsp;остальные?
              </div>

              <div className="share-button" onClick={() => setStep(step + 1)}>Следующая коробка</div>
            </div>
          )}

          {step === 5 && (
            <div className={!mobile ? 'gift12-desctope' : null}>
              <div className="gift__dsc gift__dsc_catbox">
                <img src={catbox3} />
              </div>

              <div className="buttons-double">
                <div>
                  <div className="share-button" onClick={() => setStep(step + 1)}>Поместится</div>
                </div>
                <div>
                  <div className="share-button" onClick={() => setStep(step + 1)}>Не поместится</div>
                </div>
              </div>
            </div>
          )}

          { (step === stepSize && mobile) &&  <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />}
        </div>
      </div>
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift12);
