import React, { useState, useEffect } from 'react';
import connect from '@vkontakte/vk-connect';
import { connect as reduxConnect } from 'react-redux';
import axios from 'axios';
import Home from './components/Home';
import { APP_ID, DOMAIN } from './constants';
import { hystoryLoad, shareLoad } from './components/store/actions';

import './css/app.css';

import logo from './img/logo.png'

const App = ({hystoryLoad, shareLoad}) => {
  const [fetchedUser, setUser] = useState(null);
  const [load, setLoad] = useState(null);
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [botStatus, setBotStatus] = useState(null);
  const [debug, setDebug] = useState(null);

  const [scope, setScope] = useState('');

	useEffect(() => {
		connect.subscribe(props => {
      const searchParams = new URLSearchParams(window.location.search);
      const paramsObj = {
        vk_access_token_settings: searchParams.get('vk_access_token_settings'),
        vk_app_id: searchParams.get('vk_app_id'),
        vk_are_notifications_enabled: searchParams.get('vk_are_notifications_enabled'),
        vk_is_app_user: searchParams.get('vk_is_app_user'),
        vk_is_favorite: searchParams.get('vk_is_favorite'),
        vk_language: searchParams.get('vk_language'),
        vk_platform: searchParams.get('vk_platform'),
        vk_ref: searchParams.get('vk_ref'),
        vk_user_id: searchParams.get('vk_user_id'),
        sign: searchParams.get('sign'),
      }

      const { detail } = props;
      const { type, data } = detail;

			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme');
				schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
				document.body.attributes.setNamedItem(schemeAttribute);
      }
      if(type === 'VKWebAppAccessTokenReceived'){
        setScope(data.scope);
        setToken(data.access_token);
      }

      if(type === 'VKWebAppCallAPIMethodFailed'){
        //console.log(data, 'call')
        //hystoryLoad('error');
      }

      if(type === 'VKWebAppGetUserInfoResult'){
        axios.get(`${DOMAIN}/api/get.php`, {
          params: {
            uniq: window.location.hash == '#utm_source=sharing_post' ? 1 : 0,
            ...paramsObj
          }
        })
        .then(function (response) {
          setBotStatus(response.data.subscribe);
          setUserData(response.data);
          setLoad(true);
        })
        .catch(function (error) {
          console.log(error);
        });
      }

      if(type === 'VKWebAppShowWallPostBoxFailed'){
        shareLoad(false);
      }

      if(type === 'VKWebAppShowWallPostBoxResult'){
        shareLoad('complete');



        axios.get(`${DOMAIN}/api/stats_sharing.php`, {
          params: {
            type: 'wall',
            ...paramsObj
          }
        });
      }

      if(type === 'VKWebAppCallAPIMethodResult'){
        if(data.request_id == 'photo_loaded'){
          connect.send("VKWebAppShowWallPostBox",
            {
              "message": `Ваш Новый год будет просто замурчательным! Заходите в приложение Новогодний календарь от Whiskas и получайте котоподарки: http://vk.cc/whiskas_newyear
              #сновымгодомкотики`,
              "attachments": `photo${data.response[0].owner_id}_${data.response[0].id}`
            }
          );
        }
        else{
          const request_id = data.request_id.split('|');
          if(request_id[1] == 'photo'){
            shareLoad('load');
            axios.get(`${DOMAIN}/api/send_file.php`, {
              params: {
                file: `${request_id[0]}`,
                url: data.response.upload_url,
                param: 'photo',
                ...paramsObj
              }
            })
            .then((response) => {
              if(response.data.error){
                shareLoad('error');
              }
              else{
                if(response.data.result.photo){
                  shareLoad(response.data.result.photo);
                  connect.send(
                    "VKWebAppCallAPIMethod",
                    {
                      "method": "photos.saveWallPhoto",
                      "request_id": "photo_loaded",
                      "params": {
                        "v":"5.103",
                        "access_token":request_id[3],
                        "user_id": request_id[2],
                        "photo": response.data.result.photo,
                        "server": response.data.result.server,
                        "hash": response.data.result.hash
                      }
                    }
                  );
                }
              }
            })
            .catch((error) => {
              //console.log(error)
              shareLoad('error');
            });
          }
          else{
            hystoryLoad('load');
            axios.get(`${DOMAIN}/api/send_file.php`, {
              params: {
                file: `${request_id[0]}`,
                url: data.response.upload_url,
                ...paramsObj
              }
            })
            .then((response) => {
              //console.log(response)
              if(response.data.error){
                hystoryLoad('error');
              }
              else{
                hystoryLoad('complete');

                axios.get(`${DOMAIN}/api/stats_sharing.php`, {
                  params: {
                    type: 'history',
                    ...paramsObj
                  }
                });
              }
            })
            .catch((error) => {
              //console.log(error)
              hystoryLoad('error');
            });
          }
        }
      }

      if(type === 'VKWebAppAllowMessagesFromGroupResult'){
        setBotStatus('1');
      }

      setDebug(JSON.stringify(props));
    });

    //connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});

		async function fetchData() {
			const user = await connect.sendPromise('VKWebAppGetUserInfo');
      setUser(user);
      setUserId(user.id);
		}
		fetchData();
  }, []);

	return (
    <>
      {/*<h3>debug: {debug}</h3>*/}
      <div className={load ? 'loader active' : 'loader'}>
        <img src={logo} className="home__logo" />

        <div className="loader__text">
          <div className="loader__text-1">новогодний</div>
          <div className="loader__text-2">Календарь</div>
          <div className="loader__text-3">на декабрь!</div>
        </div>
      </div>
      <div className={load ? 'home-wrapper active' : 'home-wrapper'}>
        {userData && userData.gifts && <Home fetchedUser={fetchedUser} token={token} scope={scope} userData={userData} userId={userId} botStatus={botStatus} setUserData={setUserData} />}
      </div>
    </>
  )
}

export default reduxConnect(
  null
,{ hystoryLoad, shareLoad })(App);

