import React from 'react';
import logo from './../img/logo.png';

const PageHead = ({goHome, goBack, goBot, children}) => (
  <div className="page page_head">
    <img src={logo} className="home__logo" />
    <div className="home__title">
      <div className="home__title-in">
        {!children &&  <div className="home__back" onClick={goBack} />}
        <div onClick={goHome}>Новогодний <br /><span>календарь</span></div>
      </div>
      <div className="home__chat" onClick={goBot} />
    </div>
    {children}
  </div>
);

export default PageHead;
