import { combineReducers } from 'redux';
import hystory from './hystory';
import gifts from './gifts';
import share from './share';
import popup from './popup';
import postcard from './postcard';
import playlist from './playlist';
import promocode from './promocode';
import popuppostcard from './popuppostcard';
import popupgift from './popupgift';
import frame from './frame';

export default combineReducers({
  hystory,
  gifts,
  share,
  popup,
  postcard,
  playlist,
  promocode,
  popuppostcard,
  popupgift,
  frame
});