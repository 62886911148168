import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PageHead from '../PageHead';
import { connect as reduxConnect } from 'react-redux';
import RelesLnk from '../../components/RelesLnk';

import img_d from './../../img/gifts/Ng_otkrytka_1.png';
import img_m from './../../img/gifts/Ng_otkrytka_2.png';

const Gift9 = ({index, goHome, goBot, mobile, gifts, promocode})  => {
  let codestatus = promocode.status;
  codestatus = '';
  
  if(codestatus){
    if(mobile){
      return (
        <div className="wrap">
          <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
          <div className="page page_gift">
            <div className={`gift gift${index}`}>
              <div className="gift__head">
                <div className="gift__head-num">{gifts.day}</div>
                <div className="gift-title">Промо-код от&nbsp;Whiskas</div>
                <p>Whiskas дарит тебе промо-код
                на&nbsp;скидку 50% на&nbsp;всю продукцию.
                Успей воспользоваться!</p>
                <CopyToClipboard text={codestatus}>
                  <div className="share-button">{codestatus}</div>
                </CopyToClipboard>
              </div>
      
              <a href="https://whiskas.ru/" className="gift-outer-lnk">Перейти на сайт</a>
            </div>
          </div>
          <RelesLnk />
        </div>
      );
    }
  
    return (
      <div className="wrap">
        <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
        <div className="page page_gift">
          <div className={`gift gift${index} desctope`}>
            <div className="gift__head">
              <div className="gift__head-num">{gifts.day}</div>
              <div className="gift__head-with-buttons">
                <div>
                <div className="gift-title">Промо-код от&nbsp;Whiskas</div>
                <p>Whiskas дарит тебе промо-код
                на&nbsp;скидку 50% на&nbsp;всю продукцию.
                Успей воспользоваться!</p>
                </div>
                <div>
                  <CopyToClipboard text={codestatus}>
                    <div className="share-button">{codestatus}</div>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
  
            <div className="gift__dsc">
              <div className="gift-title">Как получить</div>
  
              <div className="dgift9-rules">
                <div>
                  <div>Период</div>
                  с&nbsp;25&nbsp;декабря&nbsp;2019<br/>по&nbsp;31&nbsp;января&nbsp;2020
                </div>
                <div>
                  <div>Скидка</div>
                  50%&nbsp;на&nbsp;товар<br/>новогодней&nbsp;коллекции
                </div>
                <div>
                  <div>Где получить</div>
                  в&nbsp;онлайн-магазинах<br/>Перекресток, Бетховен
                </div>
                <div>
                  <div>Как получить</div>
                  ввести промо-код<br/>в соответствующее поле<br/>на&nbsp;сайте магазина
                </div>
              </div>
            </div>
    
            <div className="dcenter"><a href="https://whiskas.ru/" className="gift-outer-lnk">Перейти на сайт</a></div>
          </div>
        </div>
      </div>
    );
  }
  else{
    return (
      <div className="wrap">
        <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
        <div className="page page_gift">
          <div className={`gift gift${index}`}>
            <div className="gift__head">
              <div className="gift__head-num">{gifts.day}</div>
              <div className="gift-title">Промо-код от&nbsp;Whiskas</div>
                Жаль, что вы не&nbsp;зашли раньше, этот подарок уже закончился! 
                Не расстраивайтесь, лучше ловите праздничную котооткрытку!
            </div>

            <div className="gift__dsc gift__dsc_img">
              <img src={mobile ? img_m : img_d} className="gift9-postcard" />
            </div>
          </div>
        </div>

        {mobile && <RelesLnk />}
      </div>
    );
  }
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
    promocode: state.promocode
  }),
  null
)(Gift9);
