import React, { useEffect } from 'react';
import PageHead from '../PageHead';
import { connect as reduxConnect } from 'react-redux';
import ShareButton from '../ShareButton';
import RelesLnk from '../../components/RelesLnk';
import ButtonToBottom from '../../components/ButtonToBottom';

import img from '../../img/gifts/g44.jpg'

import connect from '@vkontakte/vk-connect';
import { APP_ID } from '../../constants';

const gift5Text = () => (
  <>
    <ul className="mlist">
      <li><span>Возьмите тонкую картонную коробку (например из-под пиццы или&nbsp;компьютерного монитора).</span></li>
      <li><span>Произвольно прорежьте дырочки сверху, чтобы в&nbsp;них&nbsp;помещалась лапка.</span></li>
      <li><span>Сделайте небольшие шарики из&nbsp;фольги, чтобы они легко катались внутри коробки.</span></li>
      <li><span>Приклейте на&nbsp;дно коробки резиновые фиксаторы, чтобы&nbsp;коробка не&nbsp;скользила.</span></li>
    </ul>
  </>
)

const Gift44 = ({index, goHome, goBot, token, mobile, gifts, userId, scope}) => {

  useEffect(() => {
    if(scope == ''){
      connect.send("VKWebAppGetAuthToken", {"app_id": APP_ID, "scope": "photos,stories"});
    }
  }, []);

  return (
    <div className="wrap">
      <PageHead goHome={goHome} goBot={goBot} goBack={goHome}/>
      <div className="page page_gift">
        <div className={`gift gift${index}`}>
          <div className="gift__head">
            <div className="gift__head-num">{gifts.day}</div>
  
            {mobile ? (
              <>
                <div className="gift-title">Как сделать игрушку для&nbsp;кота</div>
                Делаем игрушку для котейки, от&nbsp;которой его&nbsp;любопытство разыграется с&nbsp;новой силой!
                {scope != '' && <ButtonToBottom />}
              </>
            ) : (
              <>
                <div className="gift__head-with-buttons">
                  <div>
                    <div className="gift-title">Как сделать игрушку для&nbsp;кота</div>
                    Делаем игрушку для котейки, от&nbsp;которой его&nbsp;любопытство разыграется с&nbsp;новой силой!
                  </div>
                  <div>
                    <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
                  </div>
                </div>
              </>
            )}
  
  
  
          </div>
          {mobile ? (
            <>
              <div className="gift44-box"></div>
              <div className="gift__dsc gift__dsc_center gift__dsc_norad gift__dsc_gift5">
              {gift5Text()}
              </div>
              <ShareButton index={index} token={token} day={gifts.day} userId={userId} scope={scope} />
            </>
          ) : (
            <>
              <div className="gift__dsc">
                <div className="dgift__dsc">
                  <div>
                    <img src={img} width="291" height="319" />
                  </div>
                  <div>
                  {gift5Text()}
                  </div>
                </div>
              </div>
            </>
          )}
  
        </div>
      </div>
  
      {mobile && <RelesLnk />}
    </div>
  );
}

export default reduxConnect(
  state => ({
    gifts: state.gifts,
  }),
  null
)(Gift44);
