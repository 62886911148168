import { SET_HISTORY_LOAD } from './constants';

const initialState = {
  status: false
};

export default function hystory(state = initialState, action) {
  if(action.type === SET_HISTORY_LOAD){
    return {
      ...state,
      status: action.payload,
    };
  }
	return state;
}