export const SET_HISTORY_LOAD = 'SET_HISTORY_LOAD';
export const SET_DAY = 'SET_DAY';
export const SET_DAYS_VISITED = 'SET_DAYS_VISITED';
export const SET_SHARE_LOAD = 'SET_SHARE_LOAD';
export const SET_POPUP_STATUS = 'SET_POPUP_STATUS';
export const SET_POSTCARD_STATUS = 'SET_POSTCARD_STATUS';
export const SET_PLAYLIST_STATUS = 'SET_PLAYLIST_STATUS';
export const SET_PROMOCODE_STATUS = 'SET_PROMOCODE_STATUS';
export const SET_POPUP_POSTCARD_STATUS = 'SET_POPUP_POSTCARD_STATUS';
export const SET_POPUP_GIFT_STATUS = 'SET_POPUP_GIFT_STATUS';
export const SET_FRAME_STATUS = 'SET_FRAME_STATUS';